import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Typography } from "@mui/material";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { dark_red } from '../../../utils/colors';

interface PaymentSuccessProps {
    transactionId: string;
}

const PaymentSuccess = ({transactionId}: PaymentSuccessProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100vh',
        gap: '20px',
      }}
    >
      <CheckCircle sx={{ fontSize: 100, color: dark_red }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold'}}>
        Your payment has been received
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center'}}>
        Thank you for your purchase!
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center'}}>
        Your transaction id is {transactionId}.
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          backgroundColor: "black",
          color: 'white',

        }}
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default PaymentSuccess;
