import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dark_red } from "../../../utils/colors";
import Footer from "../adminMenuBar/AdminFooter";
import MenuBar from "../adminMenuBar/AdminMenuBar";
import AssignGroups from "./assignGroups";
import CreateGroups from "./createGroups";
import ImportRankings from "./importRankings";

interface Person{
    name: string;
    email: string;
    rankings: number[];
}

export default function AdminGroups() {
    
    const [groups, setGroups] = useState<{ name: string; capacity: number }[]>(JSON.parse(window.sessionStorage.getItem('groups') || '[]'));
    const [people, setPeople] = useState<Person[]>([]);
    const [activeStep, setActiveStep] = useState(0);


    // store the groups in session storage
    useEffect(() => {
        window.sessionStorage.setItem('groups', JSON.stringify(groups));
    }, [groups]);    

    const steps = ['Create Groups', 'Import Rankings', 'Assign Groups']; // Update the steps according to your pipeline

    return (
        <>
            <MenuBar />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                marginTop: '64px',
                minHeight: 'calc(100vh - 64px - 130px)'
            }}>
                <Typography variant="h4" sx={{
                    fontWeight: 'bold',
                    color: dark_red,
                    alignSelf: 'center',
                }}>Group Assignment Generator</Typography>
                <Stepper activeStep={activeStep} sx={{ marginTop: '20px' }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === 0 &&
                    <CreateGroups groups={groups} setGroups={setGroups} setPage={setActiveStep} />
                }
                {
                    activeStep === 1 &&
                    <ImportRankings groups={groups} setPage={setActiveStep} people={people} setPeople={setPeople} />
                }
                {
                    activeStep === 2 &&
                    <AssignGroups groups={groups} people={people} setPage={setActiveStep} />
                }
            </Box>
            <Footer />
        </>
    );
}
