import { Box, Button, Typography, Tooltip } from '@mui/material';
import React from 'react';
import { dark_red } from '../../../../utils/colors';
import { Event, Location, User, Day } from '../../../../utils/types';
import EventModal from './EventModal';
import TableWidget from '../../../widgets/TableWidget';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddUserModal from './AddUserModal';

interface EventsTableProps {
    selectedDayIndex: number;
    users: User[];
    locations: Location[];
    daysLocal: Day[];
    setDaysLocal: (days: Day[]) => void;
}

const EventsTable = ({ selectedDayIndex, users, locations, daysLocal, setDaysLocal }: EventsTableProps) => {
    const overlayStyles = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        // zIndex: 3, // Ensure it's above all other elements
    };

    const day: Day = daysLocal[selectedDayIndex]

    const addEvent = (newEvent: Event): void => {
        if (!newEvent.name || !newEvent.start || !newEvent.end) {
            alert("Please fill out all fields.");
            return;
        }
        if (day) {
            // Add a new event to the selected day
            day.isEditing = true;
            day.events.push(newEvent);
            setDaysLocal([...daysLocal]);
        }
    }

    const updateEvent = (updatedEvent: Event): void => {
        if (day) {
            // Update the selected event
            day.isEditing = true;
            day.events = day.events.map(event => event.id === updatedEvent.id ? updatedEvent : event);
            setDaysLocal([...daysLocal]);
        }
    }

    const handleDeleteEvent = (id: string): void => {
        if (day) {
            // Delete the selected event
            day.isEditing = true;
            day.deletedEvents = [...(day.deletedEvents ?? []), day.events.find(event => event.id === id) as Event]
            day.events = day.events.filter(event => event.id !== id);
            setDaysLocal([...daysLocal]);
        }
    }

    const undoDeletedEvent = (event: Event) => {
        if (day) {
            day.deletedEvents = day.deletedEvents?.filter(e => e.id !== event.id);
            day.events.push(event);
            setDaysLocal([...daysLocal]);
        }
    }

    const handleAddUsersToEvent = (day: Day, event: Event, assignedUsers: string[] | null) => {
        if (assignedUsers) {
            // Assign users to the selected event
            const dayLocal: Day = daysLocal.find(d => d.id === day.id) as Day;
            dayLocal.isEditing = true;
            const eventLocal = dayLocal.events.find(e => e.id === event.id) as Event;
            eventLocal.assignedIDs = assignedUsers;

            setDaysLocal([...daysLocal]);
        }
    }

    if (!day || day.events.length + (day.deletedEvents?.length ?? 0) === 0) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', // Centering content vertically
                gap: '10px',
                padding: '20px',
                border: '1px solid lightgrey',
                borderRadius: '5px',
                width: '50%',
                margin: '20px auto', // Centering content horizontally
                '@media (max-width: 600px)': {
                    width: '100%',
                },
                position: 'relative', // Ensure overlay can be positioned absolutely
            }}>
                {day?.isDeleted && <Box sx={overlayStyles}></Box>}
                <CalendarTodayIcon sx={{ fontSize: 'large', color: dark_red }} />
                <Typography variant="h6" sx={{ color: 'black' }}>No events added.</Typography>
                <EventModal onSubmit={addEvent} locations={locations} />
            </Box>
        );
    }

    const formatLink = (link: string) => (
            <Tooltip title={link}>
                <Typography>{link.slice(0, 15) + '...'}</Typography>
            </Tooltip>
        );
    
    
    const rows = day.events
        .sort((a, b) => {
            const padTime = (time: string) => {
                let [hours, minutes] = time.split(':');
                hours = hours.padStart(2, '0'); // Ensure two digits for hours
                return `${hours}:${minutes}`;
            };
            return padTime(a.start).localeCompare(padTime(b.start));
        })
        .map((event, i) => [
            i + 1,
            event.name,
            event.start,
            event.end,
            (event.link ?  formatLink(event.link) : event.location?.name),
            event.speaker,
            event.speakerTitle,
            <AddUserModal event={event} day={day} users={users} onClose={handleAddUsersToEvent} />,
            <EventModal onSubmit={updateEvent} event={event} onDelete={handleDeleteEvent} locations={locations} />,
        ]).concat(day.deletedEvents?.map((event, i) => [
            <Typography>{day.events.length + 1 + i}</Typography>,
            <Typography style={{ textDecoration: 'line-through' }}>{event.name}</Typography>,
            <Typography style={{ textDecoration: 'line-through' }}>{event.start}</Typography>,
            <Typography style={{ textDecoration: 'line-through' }}>{event.end}</Typography>,
            <Typography style={{ textDecoration: 'line-through' }}>{event.link ?  formatLink(event.link) : event.location?.name}</Typography>,
            <Typography style={{ textDecoration: 'line-through' }}>{event.speaker}</Typography>,
            <Typography style={{ textDecoration: 'line-through' }}>{event.speakerTitle}</Typography>,
            <Button onClick={() => { undoDeletedEvent(event) }}>Undo</Button>,
        ]) || []);

    return (
        <Box sx={{ position: 'relative' }}>
            {day.isDeleted && <Box sx={overlayStyles}></Box>}
            <Box sx={{ position: 'relative' }}>
                <TableWidget headers={['#', 'Name', 'Start', 'End', 'Location', 'Speaker', 'Speaker Title', "Assignments", ""]} rows={rows} tabs={false} />
                <br />
                <EventModal onSubmit={addEvent} locations={locations} />
            </Box>
        </Box>
    );
};

export default EventsTable;
