import { Box, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { subscribeToConferences } from '../../../utils/mutations/conferences';
import { Conference } from '../../../utils/types';
import MenuBar from '../adminMenuBar/AdminMenuBar';
import AdminConferenceCardProps from './AdminConferenceCard';
import Footer from "../adminMenuBar/AdminFooter";
import { User } from '../../../utils/types';


interface AdminHomeProps {
  user: User;
}

const AdminHome = ({user}: AdminHomeProps) => {
  const [confs, setConfs] = useState<Conference[]>([]);

  useEffect(() => {
    // Set up the listener for real-time updates
    const unsubscribe = subscribeToConferences((data: Conference[]) => {
      setConfs(data);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [user]);
  
  
  return (
    <Box>
      <MenuBar />
      <Box sx={{ marginTop: '64px', minHeight: 'calc(100vh - 64px - 110px)' }}>
        {user?.accessLevel && user?.accessLevel === 1 && <MuiAlert severity="warning" sx={{ borderRadius: '0px' }}>
          Attention! You are irreversibly editing the database directly with administrator privileges.
          Any changes can be highly destructive!
        </MuiAlert>}
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', padding: '30px' }}>
          {confs?.map((conf: Conference) => (
            <Grid item key={conf.conferenceCode} xs={8} sm={6} md={4} lg={4}>
              <AdminConferenceCardProps conf={conf} />
            </Grid>
          ))}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default AdminHome;
