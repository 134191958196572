import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dark_red } from '../../../utils/colors';
import { subscribeToSchedule } from '../../../utils/mutations/schedule';
import type { Day, Location, User } from '../../../utils/types';
import Footer from "../menuBar/Footer";
import MenuBar from '../menuBar/MenuBar';
import DayPanel from './DayPanel';

interface ScheduleProps {
    user: User;
    isSmallScreen: boolean;
}

export default function Schedule({user, isSmallScreen}: ScheduleProps) {
    const [days, setDays] = useState<Day[]>([]);

    const [value, setValue] = useState(0);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    const handleLocationClick = (location: Location) => {
        // navigate to "/confCode/map"
        navigate(`/${user.conferenceCode}/map`, { state: { location } });
    };

    useEffect(() => {
        const fetchSchedule = () => {
            if (!user.conferenceCode) return;
            const unsubscribe = subscribeToSchedule(user.conferenceCode, (data: Day[]) => {
                setDays(data);                
            });

            return () => {
                unsubscribe();
            };
        };

        // Check if schedule is already cached
        fetchSchedule();
    }, [user.conferenceCode]); // Trigger useEffect when conference code changes


    return (
        <Box>
            <MenuBar user={user} />
            <Box sx={{
                marginTop: '64px',
                padding: '20px 0px',
                minHeight: 'calc(100vh - 64px - 130px)'
            }}>
                <Typography variant="h3" sx={{
                    textAlign: 'center',
                    margin: '25px 0px',
                    color: dark_red,
                    fontWeight: 'bold',
                }}>Schedule</Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '100vw',
                }}>
                    {/* Tabs or Dropdown */}
                    {isSmallScreen ? (
                        days.length > 0 && <Select
                            value={value}
                            onChange={(e) => setValue(e.target.value as number)}
                            variant='standard'
                            sx={{ marginBottom: '10px', width: '100px', color: dark_red }}
                        >
                            {days.map((_, index) =>
                                <MenuItem key={index} value={index}>Day {index + 1}</MenuItem>
                            )}
                        </Select>
                    ) : (
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            centered
                            variant="scrollable"
                        >
                            {days.map((_, index) => (
                                <Tab key={index} label={`Day ${index + 1}`} />
                            ))}
                        </Tabs>
                    )}
    
                    {/* Tab Panels */}
                    {days.map((day, index) => (
                        value === index && (
                            <DayPanel key={index} day={day} index={index} user={user} handleLocationClick={handleLocationClick} />
                        )
                    ))}
    
                    {
                        days.length === 0 && (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '10px',
                                padding: '20px',
                                border: '1px solid lightgrey',
                                borderRadius: '5px',
                                width: '50%',
                                margin: '20px',
                                '@media (max-width: 600px)': {
                                    width: '100%',
                                },
                            }}>
                                <CalendarTodayIcon sx={{ fontSize: 'large', color: dark_red }} />
                                <Typography variant="h6" sx={{ color: 'black' }}>No schedule available</Typography>
                            </Box>
                        )
                    }
                </Box>
            </Box>
            <Footer />
        </Box>
    );    

}