import { AddCircleOutline } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
    Box,
    Button,
    IconButton,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { dark_red } from '../../../../utils/colors';
import { deleteDay, updateDay } from '../../../../utils/mutations/schedule';
import { Day, Event, Location, User } from '../../../../utils/types';
import DayTab from './DayTab';
import EventsTable from './EventsTable';

interface ScheduleTabProps {
    confCode: string;
    users: User[];
    daysLocal: Day[];
    setDaysLocal: (days: Day[]) => void;
}

const ScheduleTab: React.FC<ScheduleTabProps> = ({ confCode, users, daysLocal, setDaysLocal }) => {
    const [selectedDayIndex, setSelectedDayIndex] = useState<number>(0);
    const [newDayDate, setNewDayDate] = useState<string>("");
    const [editingNewDayDate, setEditingNewDayDate] = useState<boolean>(false);

    const locations: Location[] = [...daysLocal.reduce((locationMap, day) => {
        // Function to add locations to the map
        const addLocations = (events: Event[]) => {
            events.forEach(event => {
                if (event.location && !locationMap.has(event.location.name)) {
                    locationMap.set(event.location.name, event.location);
                }
            });
        };
    
        // Add locations from events
        addLocations(day.events);
        
        // Add locations from deleted events
        if (day.deletedEvents) {
            addLocations(day.deletedEvents);
        }
        
        return locationMap;
    }, new Map<string, Location>()).values()];
    
    
    const handleChangeDate = (day: Day, newDate: string) => {
        // Implement rename functionality
        if (daysLocal.some(d => d.date === newDate)) {
            alert("Dates must be unique.");
            return;
        }

        const i = daysLocal.findIndex(d => d.date === day.date);
        daysLocal[i].date = newDate;
        daysLocal[i].isEditing = true;

        // Create a new array with the updated day
        const updatedDays = [...daysLocal];

        // Sort the new array
        const sortedDays = updatedDays.sort((a, b) => {
            if (!a.date || !b.date) return 0;

            const aDateParts = a.date.split("/");
            const bDateParts = b.date.split("/");
            const aMonth = parseInt(aDateParts[0]);
            const bMonth = parseInt(bDateParts[0]);
            const aDay = parseInt(aDateParts[1]);
            const bDay = parseInt(bDateParts[1]);

            // Compare months first
            if (aMonth !== bMonth) {
                return aMonth - bMonth;
            }

            // If months are the same, compare days
            return aDay - bDay;
        });

        // Find the index of the updated day in the sorted array
        const newIndex = sortedDays.findIndex(d => d.date === newDate);
        setSelectedDayIndex(newIndex);
        // Update the state with the sorted array and set selectedDayId to the id of the updated day
        setDaysLocal(sortedDays);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sanitizedText = event.target.value.replace(/[^0-9/]/g, '');
        setNewDayDate(sanitizedText);
    };  
    
    const handleDelete = (day: Day) => {
        const i = daysLocal.findIndex(d => d.id === day.id);
        daysLocal[i].isDeleted = !daysLocal[i].isDeleted;
        setDaysLocal([...daysLocal]);
    };

    function generateUUID() {
        const length = 20;
        const array = new Uint8Array(length);
        window.crypto.getRandomValues(array);

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let randomString = '';

        for (let i = 0; i < length; i++) {
            randomString += characters.charAt(array[i] % charactersLength);
        }

        return randomString;
    }

    const addDay = () => {
        // if string is not of the form ?[0-9][0-9]/[0-9][0-9]
        if (newDayDate === undefined || newDayDate === "") {
            setEditingNewDayDate(false);
            return;
        }

        if (!newDayDate.match(/^\d{0,2}\/\d{2}$/)) {
            alert("Please enter a valid date in the format mm/dd");
            return;
        }

        if (daysLocal.some(day => day.date === newDayDate)) {
            alert("Date already exists.");
            return;
        }

        const newUUID = generateUUID();

        // Add a new day to the schedule
        const newDay: Day = {
            id: newUUID,
            date: newDayDate,
            events: [],
            isEditing: true,
            isDeleted: false,
            deletedEvents: [],
        };

        // Create a new array with the added day
        const updatedDays = [...daysLocal, newDay];

        // Sort the new array
        const sortedDays = updatedDays.sort((a, b) => {
            if (!a.date || !b.date) return 0;

            const aDateParts = a.date.split("/");
            const bDateParts = b.date.split("/");
            const aMonth = parseInt(aDateParts[0]);
            const bMonth = parseInt(bDateParts[0]);
            const aDay = parseInt(aDateParts[1]);
            const bDay = parseInt(bDateParts[1]);

            // Compare months first
            if (aMonth !== bMonth) {
                return aMonth - bMonth;
            }

            // If months are the same, compare days
            return aDay - bDay;
        });

        // Update the state with the sorted array and set selectedDayId to the id of the newly added day
        setDaysLocal(sortedDays);

        setEditingNewDayDate(false);
    };

    const handlePublish = async () => {
        if (!daysLocal.some(day => day.isEditing || day.isDeleted)) {
            alert("No changes to publish.");
            return;
        }

        const input = prompt("Please enter password to publish changes:");
        if (input !== "admin123") {
            alert("Password is incorrect.");
            return;
        }

        // Create a new array to hold the modified days
        const updatedDays = [];

        // Publish the schedule
        for (const day of daysLocal) {
            if (day.isDeleted) {
                // Delete the day
                await deleteDay(confCode, day.id);
                continue;
            }

            if (day.isEditing) {
                // convert the DayLocal back to Day
                const dayToSend = {
                    id: day.id,
                    date: day.date,
                    events: day.events,
                };

                // Update the day
                await updateDay(confCode, dayToSend);

                // After pushing updates, set isEditing to false
                day.isEditing = false;
            }
            
            // Add the modified day to the updated array
            updatedDays.push(day);
        }
        // Update the state with the modified days
        setDaysLocal(updatedDays);

        window.sessionStorage.removeItem(`localDays_${confCode}`);

        window.location.reload();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            {daysLocal.length === 0 ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    padding: '20px',
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    width: '50%',
                    margin: '20px auto',
                    '@media (max-width: 600px)': {
                        width: '100%',
                    },
                }}>
                    <CalendarTodayIcon sx={{ fontSize: 'large', color: dark_red }} />
                    <Typography variant="h6" sx={{ color: 'black' }}>No days added.</Typography>
                    {!editingNewDayDate ? (
                        <Button onClick={() => {
                            setNewDayDate("")
                            setEditingNewDayDate(true);
                        }}>
                            Add Day
                        </Button>
                    ) : (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <TextField
                                value={newDayDate}
                                onChange={handleDateChange}
                                placeholder="mm/dd"
                                variant="standard"
                                inputProps={{
                                    maxLength: 5,
                                }}
                                sx={{
                                    width: "100px",
                                    alignSelf: "center",
                                }}
                            />
                            <Button onClick={addDay} variant="text">Add</Button>
                        </Box>
                    )}
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            onClick={handlePublish}
                            sx={{ variant: 'text', marginBottom: '10px' }}
                        >
                            Publish
                        </Button>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            value={selectedDayIndex}
                            onChange={(_, newValue) => {
                                setSelectedDayIndex(newValue);
                            }}
                            sx={{ borderRight: 1, borderColor: 'divider', minWidth: '150px', maxHeight: 'calc(50px * 5)' }} // 48px is the approximate height of a single tab, adjust as needed
                        >
                            {daysLocal.map((day: Day) => (
                                <DayTab key={day.id} day={day} handleDelete={handleDelete} handleChangeDate={handleChangeDate} />
                            ))}
                        </Tabs>
                        {editingNewDayDate && (
                            <TextField
                                value={newDayDate}
                                placeholder="mm/dd"
                                variant="standard"
                                inputProps={{ maxLength: 5 }}
                                onChange={handleDateChange}
                                onBlur={addDay}
                                sx={{ width: "100px", alignSelf: "center" }}
                            />
                        )}
                        <IconButton
                            onClick={() => {
                                setNewDayDate("");
                                setEditingNewDayDate(true);
                            }}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                        >
                            <AddCircleOutline />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, p: 3 }}>
                        <EventsTable
                            selectedDayIndex={selectedDayIndex}
                            users={users}
                            locations={locations}
                            daysLocal={daysLocal}
                            setDaysLocal={setDaysLocal}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ScheduleTab;
