import { Box, Button, Typography } from "@mui/material";
import GLPK from "glpk.js";
import React, { useState } from "react";
import TableWidget from "../../widgets/TableWidget";
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { dark_red } from "../../../utils/colors";

interface Person {
    name: string; 
    email: string;
    rankings: number[];
    assignment?: number;
}

interface AssignGroupsProps {
    groups: { name: string; capacity: number }[];
    people: Person[];
    setPage: (page: number) => void;
}

export default function AssignGroups({ groups, people, setPage }: AssignGroupsProps) {
    const [downloaded, setDownloaded] = useState<boolean>(false);

    // Status: 0 = not started, 1 = in progress, 2 = completed, -1 = error
    const [status, setStatus] = useState<number>(0);

    const [time, setTime] = useState<number | undefined>(undefined);
    const [unhappiness, setUnhappiness] = useState<number | undefined>(undefined);

    const shuffleAndAssign = async () => {
        setStatus(1);

        // Shuffle the people inline
        for (let i = people.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [people[i], people[j]] = [people[j], people[i]];
        }

        const glpk = await GLPK();
        try {
            // Prepare the optimization problem
            const N = people.length;
            const m = groups.length;

            // Define the LP model
            const lpModel: any = {
                name: 'AssignGroups',
                objective: {
                    direction: glpk.GLP_MIN,
                    name: 'obj',
                    vars: []
                },
                subjectTo: [],
                bounds: []
            };

            // Define the objective function
            for (let i = 0; i < N; i++) {
                for (let j = 0; j < m; j++) {
                    const ranking = people[i].rankings[j];
                    lpModel.objective.vars.push({ name: `x_${i}_${j}`, coef: ranking });
                }
            }

            // Define the constraints
            for (let i = 0; i < N; i++) {
                const constraint: any = {
                    name: `c${i + 1}`,
                    vars: [],
                    bnds: { type: glpk.GLP_FX, lb: 1, ub: 1 }
                };
                for (let j = 0; j < m; j++) {
                    constraint.vars.push({ name: `x_${i}_${j}`, coef: 1 });
                }
                lpModel.subjectTo.push(constraint);
            }

            for (let j = 0; j < m; j++) {
                const constraint: any = {
                    name: `c${N + j + 1}`,
                    vars: [],
                    bnds: { type: glpk.GLP_UP, ub: groups[j].capacity }
                };
                for (let i = 0; i < N; i++) {
                    constraint.vars.push({ name: `x_${i}_${j}`, coef: 1 });
                }
                lpModel.subjectTo.push(constraint);
            }

            // Define the bounds
            for (let i = 0; i < N; i++) {
                for (let j = 0; j < m; j++) {
                    if(lpModel.bounds){
                        lpModel.bounds.push({ name: `x_${i}_${j}`, ub: 1, lb: 0, type: glpk.GLP_DB });
                    }
                }
            }
            // Solve the optimization problem
            const res: any = await glpk.solve(lpModel);
            
            let unhappiness: number = 0;

            // Map the results back to the people and store the assignments
            for (const v in res.result.vars) {
                if (res.result.vars[v] === 1) {
                    const [, i, j] = v.split('_').map(Number);
                    people[i].assignment = j;
                    unhappiness += Number(people[i].rankings[j]);
                }
            }

            setUnhappiness(Math.round(100*(unhappiness/people.length - 1))/100);
            setTime(res.time * 1000);
            setStatus(2);
        } catch (error) {
            setStatus(-1);
            console.error("Error:", error);
        }
    }

    const downloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(formatDataAsCSV());
        const link = document.createElement("a");
        link.setAttribute("href", csvContent);
        link.setAttribute("download", "assignment_data.csv");
        document.body.appendChild(link);
        link.click();
        setDownloaded(true);
    };

    const formatDataAsCSV = () => {
        let csv = "Name,Email,Assignment,";
        groups.forEach(group => {
            csv += "," + group.name;
        });
        csv += "\n";

        people.forEach(person => {
            const assigned: string = person.assignment !== undefined ? groups[person.assignment].name : "";
            csv += `${person.name},${person.email},${assigned}, `;
            person.rankings.forEach(ranking => {
                csv += `,${ranking}`;
            });
            csv += "\n";
        });

        return csv;
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            marginTop: '20px',
        }}>
            <Button onClick={shuffleAndAssign}>Shuffle and Assign</Button>
            <Button onClick={() => setPage(1)}>Back</Button>

            {
                status === 2 && (
                <Box>
                    <Typography>
                        Success! The assignment was completed in {time}ms with an unhappiness score of {unhappiness}.
                    </Typography>

                    <Button 
                        startIcon={downloaded ? <FileDownloadDoneIcon /> : <DownloadIcon />}
                        onClick={downloadCSV}
                    >Download CSV</Button>
            
                    <TableWidget search={true} headers={['Name', 'Email', ...groups.map(group => group.name)]} rows={
                        people.map((person) => [
                            person.name,
                            person.email,
                            ...person.rankings.map((ranking, i) => (
                                <Typography key={i} style={{ 
                                    backgroundColor: person.assignment === i ? dark_red : 'normal', 
                                    color: person.assignment === i ? 'white' : 'black',
                                    borderRadius: '50%', 
                                    width: '30px', 
                                    height: '30px', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center' 
                                }}>{ranking}</Typography>
                            ))
                        ])
                    }/>
                </Box>
                )
            }
        </Box>
    );
}
