import { Box } from "@mui/material";
import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import LogIn from './LogIn';
import SignUp from './SignUp';
import VerifyEmail from "./VerifyEmail";
import JoinConf from "./JoinConf";
import Planet from './planet/Planet';

import type { User } from '../../utils/types';

import ProgressiveImage from 'react-progressive-graceful-image';
import Footer from "../confPage/menuBar/Footer";

interface ProgressiveBackgroundBoxProps {
  children: React.ReactNode;
  backgroundImage: string;
  placeholderImage: string;
}

const ProgressiveBackgroundBox: React.FC<ProgressiveBackgroundBoxProps> = ({ children, backgroundImage, placeholderImage }) => {
  return (
    <ProgressiveImage
      src={backgroundImage}
      placeholder={placeholderImage}
    >
      {(src: any, loading: any) => (
        <Box
          sx={{
            width: '100%',
            minHeight: '100vh',
            backgroundImage: `url(${src})`, // Use the src provided by ProgressiveImage
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            opacity: loading ? 0.5 : 1, // Adjust opacity during loading
            position: 'relative',
          }}
        >
          {children}
        </Box>
      )}
    </ProgressiveImage>
  );
};

interface HomeProps {
  user: User | null;
  emailVerified: boolean;
  setUser: (user: User) => void;
  setEmailVerified: (emailVerified: boolean) => void;
}

const Home: React.FC<HomeProps> = ({ user, emailVerified, setUser, setEmailVerified }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:850px)');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
    <ProgressiveBackgroundBox
      backgroundImage="art/Bangkok Thailand Photo.png"
      placeholderImage="art/Bangkok Thailand Photo-compressed.png"
    >
      {!isSmallScreen && (
        <Box sx={{
          width: '50%',
          display: 'flex',
          justifyContent: 'center', 
        }}>
          <Planet />
        </Box>
      )}

      <Box sx={{
        width: isSmallScreen ? '100%' : '50%',
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
      }}>
        <Box
          sx={{
            borderRadius: '20px',
            marginY: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '25px',
            minWidth: '350px',
            width: '60%',
            overflow: 'hidden',
            position: 'relative', // Required for absolute positioning of pseudo-element
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: 'blur(4px) saturate(180%)', // Apply blur filter here
              zIndex: 0, // Ensure it's behind the content
            },
          }}
        >
          <img src="art/HPAIR Logo Banner (Black).png" alt="HPAIR Logo" style={{ marginBottom: '10px', width: '300px', zIndex: 1 }} /> 
          <Box sx={{
            zIndex: 1, // Ensure it's in front of the pseudo-element
          }}>
            {/* Conditional rendering based on user state */}
            {!user ? (
              isSignUp ? (
                <SignUp setIsSignUp={setIsSignUp} setUser={setUser} />
              ) : (
                <LogIn setIsSignUp={setIsSignUp} />
              )
            ) : (
              emailVerified ? (
                <JoinConf user={user} />
              ) : (
                <VerifyEmail setEmailVerified={setEmailVerified}/>
              )
            )}
          </Box>
        </Box>
      </Box>
    </ProgressiveBackgroundBox>
    <Footer />
    </Box>
  );
};

export default Home;
