import { AddCircleOutline, Close, MoreHoriz, Upload } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Backdrop, InputAdornment, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createProduct, deleteProduct, updateProduct, uploadImage } from '../../../../utils/mutations/products';
import { Product } from '../../../../utils/types';
import CustomInput from '../../../widgets/CustomInput'; // Import the custom input component

interface ProductModalProps {
    confCode: string;
    product?: Product;
}

const ProductModal: React.FC<ProductModalProps> = ({ confCode, product }) => {
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<Product>();

    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setLoading(false);
        setOpen(false);
        reset();
        setImageFile(null); // Clear the image file when the modal is closed
    };

    const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        if (event.target.files.length > 0){
            if (event.target.files[0].type !== 'image/png') {
                alert('Please upload a .png file.');
                return;
            }
            setImageFile(event.target.files[0]);
        }
    };

    const onSubmit = async (data: Product) => {
        const pw = prompt('Enter password');
        if(pw !== 'admin123') {
            console.error("Incorrect password");
            return;
        };
        setLoading(true);
        if(!product || !product?.id){
            const id = await createProduct(confCode, data);
            if (!!imageFile) {
                await uploadImage(confCode, id, imageFile);
            }
            handleClose();
            return;
        } else{
            // Check if there are any changes or if image file is uploaded
            const hasChanges: boolean = (data.name !== product.name) || (data.price !== product.price) || (data.description !== product.description) || !!imageFile;
            

            if (hasChanges) {
                if(!!imageFile){
                    await uploadImage(confCode, product.id, imageFile);
                }

                // Update the product
                await updateProduct(confCode, product.id, data);

            }
            handleClose();
        }
    };

    const handleDelete = async () => {
        const pw = prompt('Enter password');
        if(pw !== 'admin123') {
            console.error("Incorrect password");
            return;
        };
        if (product && product.id) {
            setLoading(true);
            await deleteProduct(confCode, product.id);
        }
        handleClose();
    }

    return (
        <>
            <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
            {product ? <IconButton onClick={() => setOpen(true)}><MoreHoriz /></IconButton> : <Button onClick={() => setOpen(true)} startIcon={<AddCircleOutline />}>Add Product</Button>}
            <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {product ? 'Edit Product' : 'Create Product'} {/* Change dialog title based on whether event is provided */}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <CustomInput
                        label="Name"
                        id="name"
                        type="text"
                        defaultValue={product?.name}
                        errorText={errors.name && errors.name.message}
                        register={register('name', { required: 'Name is required.' })}
                    />
                    <CustomInput
                        label="Description"
                        id="description"
                        type="text"
                        defaultValue={product?.description}
                        errorText={errors.description && errors.description.message}
                        register={register('description')}
                    />
                    <CustomInput
                        label="Price"
                        id="price"
                        type="text" // Change type to "text"
                        defaultValue={product?.price}
                        errorText={errors.price && errors.price.message}
                        register={register('price', {
                            required: 'Price is required',
                            min: { value: 0, message: 'Price must be greater than 0.' }
                        })}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Add inputProps to allow only numbers
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                    />
                    {product?.id && <Typography>{product.id}</Typography>}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleUploadImage}
                        style={{ display: 'none' }}
                        id="upload-image"
                    />
                    <label htmlFor="upload-image">
                    <Button
                        startIcon={<Upload />}
                        component="span"
                    >
                        Upload Image (.png)
                    </Button>
                    </label>
                    {imageFile && (
                        <img src={URL.createObjectURL(imageFile)} alt="Uploaded" width="100px" />
                    )}
                    {!imageFile && product?.imageUrl && (
                        <img src={product.imageUrl} alt="Original" width="100px" />
                    )
                    }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="error">Delete</Button>
                    <Button type="submit" color="primary">{product ? "Save" : "Create"}</Button>
                </DialogActions>
            </form>
            </Dialog>
        </>
    );
};

export default ProductModal;
