import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useState } from 'react';
import { User } from '../../../../utils/types';
import UserModal from './UserModal';
import TableWidget from '../../../widgets/TableWidget';

interface RegistrantTabProps {
  registrants: User[];
}

const RegistrantTab: React.FC<RegistrantTabProps> = ({ registrants }: RegistrantTabProps) => {
  const [filterTicketClasses, setFilterTicketClasses] = useState<string[]>([]);
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const rows = registrants
    .filter((user)=>(filterTicketClasses.length === 0 || filterTicketClasses.includes(user.ticketClass ?? '')))
    .slice()
    .sort((a, b) => (b.paymentTime?.getTime() || 0) - (a.paymentTime?.getTime() || 0))
    .map((user, i) => [
      i+1,
      user.displayName,
      user.paymentTime?.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }) ?? '',
      user.email,
      user.ticketClass,
      <UserModal user={user} />
   ]);

  const uniqueTicketClasses: string[] = Array.from(new Set(
    registrants
      .map(user => user.ticketClass)
      .filter((ticketClass): ticketClass is string => ticketClass !== null)
  ));

  const handleChangeFilterClass = (event: any) => {
    const selectedClasses = event.target.value as string[];
    setFilterTicketClasses(selectedClasses);
  };

  const handleDownload = async () => {
    // Add column names as the first row
    const columnNames = 'DisplayName,Email,UID,TicketClass,PaymentTime,Preferred Name,Birthdate,Primary Nationality,Dietary Restrictions,Food Allergies,Accommodations';
    
    // Map the registrants data to CSV format
    const csvData = registrants.map((registrant) => {
      const { displayName, email, uid, ticketClass } = registrant;
      const { preferredName, birthdate, nationality, dietaryRestriction, foodAllergies, accomodations } = registrant.preferences ? JSON.parse(registrant.preferences) : { preferredName: undefined, birthdate: undefined, nationality: undefined, dietaryRestriction: undefined, foodAllergies: undefined, accomodations: undefined };
      return `${displayName},${email},${uid},${ticketClass},${registrant.paymentTime?.getTime() ?? ''},${preferredName ?? ''},${birthdate ?? ''},${nationality ?? ''},${dietaryRestriction ?? ''},${foodAllergies ?? ''},${accomodations ?? ''}`;
    });

    // Combine column names and registrants data
    const csvString = `${columnNames}\n${csvData.join('\n')}`;
    
    // Create a Blob with the CSV data
    const blob = new Blob([csvString], { type: 'text/csv' });
  
    // Create a download URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create an anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = 'registrants.csv';
  
    // Append the anchor element to the document
    document.body.appendChild(a);
  
    // Programmatically trigger a click on the anchor element
    a.click();
  
    // Remove the anchor element from the document
    document.body.removeChild(a);
  
    setDownloaded(true);
  }

  return (
    <Box sx={{ width: '100%', overflow: 'auto', marginBottom: '20px', padding: '20px' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {registrants?.length} registrants
      </Typography>
      <Typography variant="body2">Filter by ticket class</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '200px' }}>
          <Select
            value={filterTicketClasses}
            onChange={handleChangeFilterClass}
            size='small'
            displayEmpty
            multiple
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.sort().map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {uniqueTicketClasses.map((ticketClass: string) => (
              <MenuItem key={ticketClass} value={ticketClass}>
                <Checkbox checked={filterTicketClasses.includes(ticketClass)} />
                <ListItemText primary={ticketClass} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="text"
          startIcon={downloaded ? <FileDownloadDoneIcon /> : <DownloadIcon />}
          onClick={handleDownload}
          sx={{ color: 'black' }}
        >
          Download
        </Button>
      </Box>
      <TableWidget rows={rows} search={true} headers={["#", "Name", "Date", "Email", "Class", ""]} />
    </Box>
  );  
};

export default RegistrantTab;
