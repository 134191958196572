import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { Product, User, Payment } from "../../../utils/types";
import PaymentWidget from "../../widgets/PaymentWidget";
import { dark_red } from '../../../utils/colors';

interface CheckoutProps {
    cart: { [productId: string]: number };
    products: Product[];
    user: User;
    setQuantity: (product: Product, quantity: number) => void;
    onClose: () => void;
    onSuccess: (payment: Payment) => void;
}

const Checkout: React.FC<CheckoutProps> = ({ cart, user, products, setQuantity, onClose, onSuccess}) => {
    const getProductById = (productId: string): Product | undefined => {
        return products.find(product => product.id === productId);
    };

    const calculateTotal = (): number => {
        return Object.keys(cart).reduce((total, productId) => {
            const product = getProductById(productId);
            if (!product) return total;
            return total + (product.price * cart[productId]);
        }, 0);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
                <Button onClick={onClose} startIcon={<ArrowBackIcon />}>Continue shopping</Button>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "flex-start",
                alignSelf: 'center',
                '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',

                }
            }}>
                {/* Box for Items Table */}
                <Box>
                    <Typography sx={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        p: 2,
                    }}>Checkout</Typography>
                    <TableContainer component={Paper} elevation={0} sx={{ borderRadius: 0 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(cart).map(productId => {
                                    const product = getProductById(productId); // Assuming you have a function to retrieve product by ID
                                    if (!product) return null; // Product not found
                                    return (
                                        <TableRow key={productId}>
                                            <TableCell><img src={product.imageUrl} alt={product.name} width="75px" /></TableCell>
                                            <TableCell>{product.name}</TableCell>
                                            <TableCell>${product.price}</TableCell>
                                            <TableCell>
                                                <Select
                                                    // native
                                                    value={cart[productId]}
                                                    variant="standard"
                                                    onChange={(e) : void => {
                                                        setQuantity(product, parseInt(String(e.target.value)));
                                                    }}
                                                >
                                                    {[0, 1, 2, 3].map(option => (
                                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {/* Box for price totals */}
                <Box marginTop={1} marginLeft={1}>
                    <Box bgcolor={dark_red} padding={2} marginBottom={1}>
                        <Typography sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            marginBottom: '10px',
                            fontSize: '1.5rem',
                        }}>Summary</Typography>
                        <Table>
                            <TableBody>
                                {Object.keys(cart).map(productId => {
                                    const product = getProductById(productId);
                                    if (!product) return null;
                                    const quantity = cart[productId];
                                    const subtotal = product.price * quantity;
                                    return (
                                        <TableRow key={productId} sx={{ border: 'none' }}>
                                            <TableCell sx={{ color: 'white', border: 'none' }}>{quantity} x</TableCell>
                                            <TableCell sx={{ color: 'white', border: 'none' }}>{product.name}</TableCell>
                                            <TableCell sx={{ color: 'white', border: 'none' }}>${subtotal}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow sx={{ borderTop: '1px solid white' }}>
                                    <TableCell sx={{ color: 'white', border: 'none' }}>Total:</TableCell>
                                    <TableCell sx={{ border: 'none' }}></TableCell>
                                    <TableCell sx={{ color: 'white', border: 'none' }}>${calculateTotal()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                    <PaymentWidget products={cart} joinCode={null} onSuccess={onSuccess} user={user} />
                </Box>
            </Box>
        </Box>
    );
};

export default Checkout;
