import { Alert, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { useNavigate, useParams } from "react-router-dom";
import { pink } from "../../utils/colors";
import { subscribeToProducts } from "../../utils/mutations/products";
import type { Product, User } from "../../utils/types";
import Carousel from "../widgets/Carousel";
import Footer from "./menuBar/Footer";
import MenuBar from "./menuBar/MenuBar";

interface ConfPageProps {
  user: User; 
  isSmallScreen: boolean;
}

export default function ConfPage({ user, isSmallScreen }: ConfPageProps) {
  const { confCode } = useParams();
  const [confetti, setConfetti] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false); // State to manage showing the alert

  const [productImages, setProductImages] = useState<string[]>([]);

  /**
   * Handles fetching and caching product images based on the user's conference code.
   * 
   * This useEffect hook performs the following actions:
   * 
   * 1. Sets the `confetti` state to `true`, likely to display some confetti animation.
   * 
   * 2. Checks if product images are cached in the browser's sessionStorage. If so, it
   *    sets the `productImages` state directly from the cached data.
   * 
   * 3. If the product images are not cached, it checks if the user has a conference
   *    code (`confCode`). If so, it calls the `subscribeToProducts` function, which
   *    likely fetches the product data from a server or database. 
   * 
   *    a. The product data is then mapped to an array of image URLs.
   *    b. The array of image URLs is stored in the browser's sessionStorage, so that
   *       it can be reused the next time the component mounts.
   */
  useEffect(() => {
    setConfetti(true);
    if (confCode) {
      subscribeToProducts(confCode, (data) => {
        setProductImages(data.map((product: Product) => (
          product.imageUrl
        )));
      });
    }
    
    const cachedProductImages = JSON.parse(sessionStorage.getItem('productImages') || '[]');
    if (cachedProductImages.length > 0) {
        // If product images are cached, set them directly from the cache
        setProductImages(cachedProductImages);
    } else {
        // If product images are not cached, fetch them
        if (confCode) {
            subscribeToProducts(confCode, (data) => {
                const images = data.map((product: Product) => product.imageUrl);
                // Store product images in the cache
                sessionStorage.setItem('productImages', JSON.stringify(images));
                setProductImages(images);
            });
        }
      }
  }, [confCode]);

  useEffect(() => {
    // Check if preferences JSONified field is not filled out
    if (!user.preferences) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [user.preferences]);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 30,
    elementCount: 200,
    dragFriction: 0.10,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const navigate = useNavigate();

  return (
  !isSmallScreen ? 
    (<>
    <MenuBar user={user} />
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginTop: '64px' }}>
      <Confetti active={confetti} config={confettiConfig} />
      <Box sx={{
        backgroundImage: 'url(/art/home-city.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '33vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}>
        <Typography variant="h2" sx={{ mb: 2, fontWeight: "bold" , textAlign: "center" }}>Congratulations, {user?.displayName?.split(' ')[0]}!</Typography>
      </Box>
      
      {showAlert && (
        <Alert severity="warning" sx={{ width: '100%', justifyContent: 'center'}}>
          Your preferences are not filled out. Please fill them out{' '}
          <Typography
            variant="body2"
            component="span"
            onClick={() => {
              sessionStorage.setItem('tab',"1");
              navigate('settings');
            }}
            sx={{ cursor: 'pointer', fontWeight: 'bold'}}
          >
            here
          </Typography>
          .
        </Alert>
      )}

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
      }}>
        <Box sx={{
          maxWidth: '45%',
          padding: '130px 40px'
        }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>Welcome to Harvard College for Asian and International Relations' Asia Conference!</Typography>
          <Typography variant="h6">We are so excited to see you in August and believe you have the potential to make a meaningful impact on the world! You were selected from a pool of thousands of qualified applicants, demonstrating the exceptional qualities and abilities that set you apart.</Typography>
        </Box>
        <iframe title="welcome video" style={{ width: '40vw', height: '45vh' }} src="https://www.youtube.com/embed/rW66OKdKxmY?si=GgR7NPQTeTNIMnLa?autoplay=1&mute=0">
        </iframe>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width:'100%', background: pink}}>
          <img src="/art/ACONF 2024 Logo with Text Set white transparent bg.png" alt="Theme" style={{ width: '75%' }} />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 4,
        padding: 2,
        marginRight: '10%',
      }}>
        <Box sx={{
          maxWidth: '70%',
          padding: '150px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" , textAlign: 'center', mb: 2}}>ACONF Merchandise</Typography>
          <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, maxWidth: '60%'}}>Check out our store below for merch such as hats, mugs, t-shirts, and more!</Typography>
          <Button variant="contained" onClick={()=>{navigate("store")}} sx={{ width: '200px', height: '50px', fontSize: '1.2rem' }}>HPAIR Store</Button>
        </Box>
        <Carousel sx={{
          width: "35vw",
          height: "30vw",
        }} images={productImages}/>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, padding: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: 'center', mb: 2 }}>Why the elephant?</Typography>
        <Typography variant="h6" sx={{ textAlign: 'justify', maxWidth: '70%' }}>
        The elephant, Thailand's national animal, has been intricately woven into the fabric of Thai history and culture for centuries, making it an emblematic figure that truly represents Thailand. Its significance extends beyond mere symbolism; the elephant is revered for its longevity, a trait that harmoniously aligns with our theme of sustainability. Together with the Ratchaphruek, Thailand's national flower, these symbols embody our commitment to nurturing nature and fostering a sustainable future. They reflect a vision where cultural preservation and technological advancement go hand in hand, underscoring our dedication to environmental stewardship and the promotion of sustainable practices that honor our rich heritage. This union of the elephant and the Ratchaphruek not only celebrates the beauty of Thailand but also our resolve to protect and sustain the natural and cultural landscapes for generations to come.
        </Typography>
        <img src="/art/ACONF 2024 LOGO - Color Ver-02.png" alt="Theme" style={{ width: '15%' }} />
      </Box>
    </Box>
    <Footer />
    </>)
    : 
    (<>
    <MenuBar user={user} />
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginTop: '64px' }}>
      <Confetti active={confetti} config={confettiConfig} />
      <Box sx={{
        backgroundImage: 'url(/art/home-city.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '20vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}>
        <Typography variant="h4" sx={{ fontWeight: "bold" , textAlign: "center" }}>Congratulations, {user?.displayName?.split(' ')[0]}!</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        padding: '20px'
      }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: 'center' }}>Welcome to Harvard College for Asian and International Relations' Asia Conference!</Typography>
        <Typography variant="h6" sx={{textAlign: 'justify'}}>We are so excited to see you in August and believe you have the potential to make a meaningful impact on the world! You were selected from a pool of thousands of qualified applicants, demonstrating the exceptional qualities and abilities that set you apart.</Typography>
        <iframe title="welcome video" style={{ width: '80vw', height: 'auto'}} src="https://www.youtube.com/embed/rW66OKdKxmY?si=GgR7NPQTeTNIMnLa?autoplay=1&mute=0">
        </iframe>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width:'100%', background: pink}}>
          <img src="/art/ACONF 2024 Logo with Text Set white transparent bg.png" alt="Theme" style={{ width: '85%' }} />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '50px',
      }}>
        <Box sx={{
          maxWidth: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" , textAlign: 'center', marginTop: '30px'}}>ACONF Merchandise</Typography>
          <Typography variant="h6" sx={{ textAlign: 'justify',maxWidth: '80%'}}>Check out our store below for merch such as hats, mugs, t-shirts, and more!</Typography>
          <Button variant="contained" onClick={()=>{navigate("store")}} sx={{ width: '200px', height: '50px', fontSize: '1.2rem' }}>HPAIR Store</Button>
        </Box>
        <Carousel sx={{
          width: "80vw",
          height: "20vh",
        }} images={productImages}/>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, padding: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: 'center', mb: 2 }}>Why the elephant?</Typography>
        <Typography variant="h6" sx={{ textAlign: 'justify', maxWidth: '70%' }}>
        The elephant, Thailand's national animal, has been intricately woven into the fabric of Thai history and culture for centuries, making it an emblematic figure that truly represents Thailand. Its significance extends beyond mere symbolism; the elephant is revered for its longevity, a trait that harmoniously aligns with our theme of sustainability. Together with the Ratchaphruek, Thailand's national flower, these symbols embody our commitment to nurturing nature and fostering a sustainable future. They reflect a vision where cultural preservation and technological advancement go hand in hand, underscoring our dedication to environmental stewardship and the promotion of sustainable practices that honor our rich heritage. This union of the elephant and the Ratchaphruek not only celebrates the beauty of Thailand but also our resolve to protect and sustain the natural and cultural landscapes for generations to come.
        </Typography>
        <img src="/art/ACONF 2024 LOGO - Color Ver-02.png" alt="Theme" style={{ width: '200px' }} />
      </Box>
    </Box>
    <Footer />
    </>)
  );
}
   
