import { Box, Button, Checkbox, Typography, Backdrop, CircularProgress } from "@mui/material";
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { dark_red } from '../../utils/colors';
import { auth } from '../../utils/firebase';
import type { Payment, User } from '../../utils/types';
import PaymentWidget from '../widgets/PaymentWidget';
import CustomInput from "../widgets/CustomInput";
import { addPaymentInfo } from "../../utils/mutations/payments";
import { refreshUser } from "../../utils/firebase";

interface JoinConfProps {
  user: User;
}

interface FormValues {
  joinCode: string;
  isVerified: boolean;
  plusExec: boolean;
}


export default function JoinConf({ user }: JoinConfProps): JSX.Element {
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);

  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      joinCode: '',
      isVerified: false,
    }
  });

  const joinCode = watch("joinCode");
  const plusExec = watch("plusExec");

  const onPaymentSuccess = (payment: Payment) => {
    console.log(payment);
    // reload the page
    window.location.reload();
  }

  const handleValidation = async (formData: FormValues) => {
    if (joinCode === "OBSERVER") {
      // if they are an hpair account, give them observer status
      const url = 'https://setcredential-2t5cbdn56q-uc.a.run.app';
      const data = {
        uid: user.uid,
        accessLevel: 2,
      }
      
      try {
        // set delay to show the backdrop
        setShowBackdrop(true);

        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        
        await refreshUser();

        window.location.reload();
      } catch (error) {
        console.error('Error:', error);
      }

    } else if (joinCode === "ADMIN") {
      // if they are an hpair account, give them observer status
      const url = 'https://setcredential-2t5cbdn56q-uc.a.run.app';
      const data = {
        uid: user.uid,
        accessLevel: 1,
      }
      
      try {
        setShowBackdrop(true);

        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        // set delay to show the backdrop
        await refreshUser();

        window.location.reload();
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      /*
        The join code is not "OBSERVER" or "ADMIN", so we will check if it is a valid conference code.
      */

      // check if the terms are checked if they need to be
      if (joinCode.slice(0, 7) === "ACONF24" && !formData.isVerified) {
        return; // Do nothing, let form validation handle it
      }
      // check if the join code is valid
      if (formData.joinCode === '') {
        return; // Do nothing, let form validation handle it
      }

      const res = await axios.post('https://verifycode-2t5cbdn56q-uc.a.run.app', {
        code: formData.joinCode,
        email: user.email,
      });

      const { isValid, price } = res.data;

      if (isValid) {
        if (formData.joinCode.split('-')[1] === "F" && !plusExec) {
          // Free conference code
          // Add payment info to the database and complete the registration
          const payment: Payment = {
            type: 'Free',
            amount: 0,
            transactionId: 'N/A',
            item: formData.joinCode,
            uid: user.uid,
            dateCreated: new Date(),
            customerCode: 'N/A',
          };

          await addPaymentInfo(payment);

          await axios.post("https://completepayment-2t5cbdn56q-uc.a.run.app", {
              uid: user.uid,
              paymentId: payment.transactionId,
              cart: [{ type: "ticket", item: formData.joinCode, quantity: 1 }]
          });

          onPaymentSuccess(payment);
        } else {
          // Paid conference code
          // Set the price and show the payment widget
          setPrice(price);
          setIsPayment(true);
        }
      } else {
        // Set error for joinCode field
        setValue('joinCode', '', { shouldValidate: true }); // Clear the input
      }
    } // end if-else
  }

  return (
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
      onClick={()=>setShowBackdrop(false)}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    {(!isPayment) ? (
      <Box sx={{
        width: "300px",
      }}>
        <Typography variant="h4" sx={{
          color: 'black',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}>Join Conference</Typography>
        <form onSubmit={handleSubmit(handleValidation)}>
          <CustomInput
            type="text"
            id="join-code"
            label="Conference Join Code"
            variant='standard'
            register={register("joinCode", { required: true })}
            color="secondary"
            error={!!errors.joinCode} // Set error state based on form validation
            helperText={errors.joinCode && "Invalid join code"} // Display error message if joinCode is invalid
          />
          {joinCode.slice(0, 7) === "ACONF24" && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  {...register("plusExec")}
                  size="small"
                  color="primary"
                  sx={{ borderRadius: '50%' }}
                />
                <Typography variant="body2" sx={{ color: 'black' }}>
                  Add Executive Seminar (+$75)
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  {...register("isVerified", { required: true })}
                  size="small"
                  color="primary"
                  sx={{ borderRadius: '50%' }}
                />
                <Typography variant="body2" sx={{ color: 'black' }}>
                  I accept the <a style={{ textDecoration: 'none', color: dark_red }} href="/documents/terms_and_conditions.html" target="_blank">Terms and Conditions.</a>
                </Typography>
              </Box>
              {errors.isVerified && <Typography variant="body2" sx={{ color: 'red' }}>You must accept the Terms and Conditions.</Typography>}
            </>
          )}  
          <Button variant="contained" type="submit" sx={{ 
            width: '100%',
            color: 'white', 
            backgroundColor: dark_red, 
            borderRadius: '3px',
            marginTop: '15px',
          }}>Join</Button>
        </form>
        <Button
          variant="text"
          onClick={() => auth.signOut()} // Go back to the sign-in form
          sx={{ marginTop: '8px', backgroundColor: 'transparent', color: 'black', width: '100%'}}
        >
          Sign out
        </Button>
      </Box>
    ) :
      (
        <Box sx={{
          width: '300px',
        }}>
        <Typography variant="h4" sx={{
          color: 'black',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}>Payment</Typography>
          <Typography variant="body1" sx={{color: 'black'}}>Please complete payment of ${price + (plusExec ? 75 : 0)} USD to complete registration.</Typography>
          <PaymentWidget
            user={user}
            joinCode={joinCode}
            products={plusExec ? { 'IgXOQfxaIjUSY5AgcUd1': 1 } : null}
            onSuccess={onPaymentSuccess}
          />
          <Button
            variant="text"
            color="secondary"
            onClick={() => setIsPayment(false)} // Go back to the sign-in form
            style={{ marginTop: '8px', backgroundColor: 'transparent', color: 'white' }}
          >
            Back
          </Button>
        </Box>
      )}
    </>
  );
}
