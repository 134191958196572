import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccountMenu from './AccountMenu';


import type { User } from '../../../utils/types';
import { dark_red } from '../../../utils/colors';


interface MenuBarProps {
  user: User | null;
}

const menuItems = [
  { name: 'Store', path: 'store' },
  { name: 'Schedule', path: 'schedule' },
  { name: 'Map', path: 'map' },
  // { name: 'FAQs', path: 'faqs' },
];


export default function MenuBar({ user }: MenuBarProps) {
  const navigate = useNavigate();
  const { confCode } = useParams() ?? null;
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null);


  const isSmallScreen = useMediaQuery('(max-width:500px)'); 

  const handleMobileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ height: '64px', zIndex: 1, backgroundColor: 'white', }} elevation={0}>
        <Toolbar sx={{ width: '100%', color: 'black'}}>
          {isSmallScreen ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <IconButton color="inherit" onClick={handleMobileMenuClick}>
                <MenuIcon />
              </IconButton>
              <Button onClick={() => navigate(`/${confCode}`)} sx={{ width: '300px', '&:hover': { backgroundColor: 'transparent' } }} disableFocusRipple disableRipple disableTouchRipple>
                <div style={{ width: '100%', height: '64px', background: `url("/art/hpair-logo.png") no-repeat center/contain` }} />
              </Button>
              <AccountMenu user={user} confCode={confCode ?? null} />
            </Box>
          ) : (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button onClick={() => navigate(`/${confCode}`)} sx={{ width: '300px', '&:hover': { backgroundColor: 'transparent' } }} disableFocusRipple disableRipple disableTouchRipple>
                <div style={{ width: '100%', height: '64px', background: `url("/art/HPAIR Logo Banner (Black).png") no-repeat center/contain` }} />
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px'}}>
                {menuItems.map((menuItem) => (
                  <Button
                    key={menuItem.path}
                    sx={{
                      color: (window.location.pathname.includes(menuItem.path) ? dark_red : 'inherit'),
                      '&:hover': { 
                        backgroundColor: 'transparent' 
                      }
                    }}
                    variant="text"
                    onClick={() => navigate(`/${confCode}/${menuItem.path}`)}
                    disableTouchRipple
                    disableFocusRipple
                    disableRipple
                  >
                    {menuItem.name}
                  </Button>
                ))}
                <AccountMenu user={user} confCode={confCode ?? null} sx={{color: (window.location.pathname.includes("settings") ? dark_red : 'inherit') }}/>
              </Box>
            </Box>
          )}
        </Toolbar>

        {/* Mobile menu */}
        {mobileMenuAnchor && (
          <Menu anchorEl={mobileMenuAnchor} open={Boolean(mobileMenuAnchor)} onClose={handleMobileMenuClose}>
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.path}
                onClick={() => navigate(`/${confCode}/${menuItem.path}`)}
              >
                {menuItem.name}
              </MenuItem>
            ))}
          </Menu>
        )}
      </AppBar>
    </>
  );
}
