import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { removeUser } from '../../../../utils/mutations/users';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { getPaymentInfo } from '../../../../utils/mutations/payments';
import { Payment, User } from '../../../../utils/types';

interface Props {
  user: User;
}

const UserModal: React.FC<Props> = ({ user }: Props) => {
  const [paymentInfo, setPaymentInfo] = useState<Payment | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(user?.transactionId?.[0] ?? null);

  useEffect(() => {
    if (open && selectedTransactionId && selectedTransactionId !== "N/A") {
      // Fetch payment information when the modal is opened and transaction ID is available
      getPaymentInfo(selectedTransactionId)
        .then((data: Payment | null) => {
          if (data) {
            setPaymentInfo(data);
          } else {
            // Handle case where payment information is not found
            console.error(`Payment information for transaction ID ${selectedTransactionId} not found`);
          }
        });
    }
  }, [open, selectedTransactionId]);

  async function handleRemoveUser() {
    // Ask the user for a password
    const enteredPassword = prompt(`Please enter the name of the user you are trying to remove: ${user?.displayName}.`);

    // Check if the password is correct (you should replace 'correctPassword' with the actual correct password)
    if (enteredPassword === user?.displayName) {
      // Password is correct, proceed with removal
      if (user && user.uid && user.conferenceCode) {
        await removeUser(user.uid);
      }
    } else {
      // Incorrect password, display a message to the user
      alert("Incorrect password. User removal cancelled.");
    }
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <MoreHorizIcon />
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="md" fullWidth>
        <Box style={{ padding: '20px' }}>
          <DialogTitle component="span" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">{user?.displayName}</Typography>
            <IconButton edge="end" color="inherit" onClick={() => setOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TableContainer>
                <Table>
                  <TableBody>
                  <TableRow>
                      <TableCell component="th" scope="row">
                        UID:
                      </TableCell>
                      <TableCell>{user?.uid}</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell component="th" scope="row">
                        Transaction Id:
                      </TableCell>
                      <TableCell>
                        {paymentInfo ? 
                      <Select
                        value={selectedTransactionId}
                        onChange={(e) => setSelectedTransactionId(e.target.value as string)}
                        sx={{
                          fontSize: 'inherit',
                        }}
                        variant="standard"
                      >
                        {user?.transactionId?.map((id) => (
                          <MenuItem key={id} value={id}>
                            {id}
                          </MenuItem>
                        ))}
                      </Select> : 'N/A'}
                      </TableCell>
                    </TableRow>
                    {paymentInfo && 
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Amount:
                      </TableCell>
                      <TableCell>${paymentInfo.amount}</TableCell>
                    </TableRow>}
                    {paymentInfo && <TableRow>
                      <TableCell component="th" scope="row">
                        Time:
                      </TableCell>
                      <TableCell>{paymentInfo.dateCreated.toLocaleString() ?? ''}</TableCell>
                    </TableRow>}
                    {paymentInfo && <TableRow>
                      <TableCell component="th" scope="row">
                        Item:
                      </TableCell>
                      <TableCell>{paymentInfo.item ?? 'N/A'}</TableCell>
                    </TableRow>}
                    {paymentInfo && <TableRow>
                      <TableCell component="th" scope="row">
                        Customer Code:
                      </TableCell>
                      <TableCell>{paymentInfo.customerCode}</TableCell>
                    </TableRow>}
                    {paymentInfo && <TableRow>
                      <TableCell component="th" scope="row">
                        Transaction Type:
                      </TableCell>
                      <TableCell>{paymentInfo.type ?? 'N/A'}</TableCell>
                    </TableRow>}
                    <TableRow>
                      <TableCell component="th" scope="row">Preferred Name:</TableCell>
                      <TableCell>{(user.preferences && JSON.parse(user.preferences).preferredName) ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">Birthdate:</TableCell>
                      <TableCell>{(user.preferences && JSON.parse(user.preferences).birthdate) ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">Dietary Restriction:</TableCell>
                      <TableCell>{(user.preferences && JSON.parse(user.preferences).dietaryRestriction) ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">Food Allergies:</TableCell>
                      <TableCell>{(user.preferences && JSON.parse(user.preferences).foodAllergies) ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">Accommodations:</TableCell>
                      <TableCell>{(user.preferences && JSON.parse(user.preferences).accomodations) ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">Primary Nationality:</TableCell>
                      <TableCell>{(user.preferences && JSON.parse(user.preferences).nationality) ?? '-'}</TableCell>
                    </TableRow>                  
                  </TableBody>
                </Table>
              </TableContainer>
            
            <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button onClick={handleRemoveUser}>
                Remove User
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default UserModal;
