import { Check, Close, WarningAmberOutlined } from "@mui/icons-material";
import { Backdrop, Box, Button, FormControl, MenuItem, TextField, Typography } from "@mui/material";
import QrScanner from "qr-scanner";
import React, { useEffect, useRef, useState } from "react";
import { dark_red } from "../../../../utils/colors";
import { scanID, subscribeToSchedule } from "../../../../utils/mutations/schedule";
import type { Day, Event } from "../../../../utils/types";

interface AdminQrScannerProps {
  confCode: string;
}

const AdminQrScanner = ({ confCode }: AdminQrScannerProps) => {
  const scanner = useRef<QrScanner | null>(null);
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(false);
  const [days, setDays] = useState<Day[]>([]);
  const [selectedDay, setSelectedDay] = useState<Day | undefined>();
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();

  const refractoryPeriod = 2000; // 2s

  const [lastScanned, setLastScanned] = useState<string>("");
 
  /*
  * 0: No validation
  * 1: Fail
  * 2: Success
  * 3: Previously Scanned
  */
  const [validationState, setValidationState] = useState<number>(0);

  useEffect(() => {
    const unsubscribe = subscribeToSchedule(confCode, (data: Day[]) => {
      setDays(data);
    });
    return () => {
      unsubscribe();
    };
  }, [confCode]);

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    // Don't scan during `refractoryPeriod` ms after a successful scan 
    if(lastScanned !== "") return;

    if(!result || !result.data || !selectedDay) return;

    const uid = result.data;
    if (!uid || !selectedEvent || !selectedEvent.assignedIDs) return;

    setLastScanned(uid);

    const assigned: boolean = selectedEvent.assignedIDs.includes(uid);
    const scanned: boolean = selectedEvent.scannedIDs.includes(uid);

    if(scanned) {
      setValidationState(3);
    } else if (assigned) {
      setValidationState(2);
    } else {
      setValidationState(1);
    }
  };

  const onScanFail = (err: string | Error) => {
    console.log(err);
  };

  useEffect(() => {
    if (videoEl.current && selectedDay && selectedEvent && qrOn) {
      if (scanner.current) {
        scanner.current.destroy(); 
        scanner.current = null;
      }

      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
          onDecodeError: onScanFail,
          preferredCamera: "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
          overlay: qrBoxEl.current || undefined,
          maxScansPerSecond: 15,
      });

      scanner.current.start().catch((err) => {
        console.error("Error starting QR Scanner:", err);
        setQrOn(false);
      });
    } else if (scanner.current) {
      scanner.current.stop();
    }

    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, selectedEvent, qrOn]);

  useEffect(() => {
    if (!qrOn) {
      console.error("Camera is blocked or not accessible. Please allow camera in your browser permissions and reload.");
    }
  }, [qrOn]);

  useEffect(() => {
    if (validationState > 0) {
      const timeoutId = setTimeout(() => {
        setValidationState(0);
        if(lastScanned !== "" && selectedDay && selectedEvent) {
          scanID(confCode, selectedDay.id, selectedEvent.id, lastScanned);
          selectedEvent.scannedIDs.push(lastScanned);
          setLastScanned("");
        }
      }, refractoryPeriod);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationState, confCode, selectedDay, selectedEvent]);

  const handleSelectEvent = (event: Event|undefined) => {
    if (!event) return;
    setSelectedEvent(event);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {validationState > 0 && <Backdrop
        open={validationState > 0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: validationState === 1 ? "rgb(255,67,67)"   : // Failure (red)
                           validationState === 2 ? "rgb(75,181,67)"   : // Success (green)
                                                   "rgb(255, 191, 0)" , // Previously Scanned (yellow)

        }}
      >
        <Typography variant="h1" sx={{ color: "white" }}>
          {
            validationState === 1 ? <Close sx={{ fontSize: '150px' }} /> :                 // Failure (red)
            validationState === 2 ? <Check sx={{ fontSize: '150px' }} /> :                 // Success (green)
                                    <WarningAmberOutlined sx={{ fontSize: '150px' }} />    // Previously Scanned (yellow)
          }
        </Typography>
      </Backdrop>}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', alignItems: 'flex-start' }}>
        <FormControl fullWidth>
          <Typography variant="body1" sx={{ color: 'black', marginBottom: '0.25rem', fontWeight: 'bold' }}>
            Select Day
          </Typography>
          <TextField
            select
            variant="standard"
            placeholder="Select Day"
            sx={{ width: '300px' }}
            value={selectedDay?.id || ""}
            onChange={(e) => {
              const day = days.find(day => day.id === e.target.value);
              setSelectedDay(day);
              setSelectedEvent(undefined);
            }}
          >
            {days.map((day) => (
              <MenuItem key={day.id} value={day.id}>
                {day.date}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        {selectedDay && (
          <FormControl fullWidth>
            <Typography variant="body1" sx={{ color: 'black', marginBottom: '0.25rem', fontWeight: 'bold' }}>
              Select Event
            </Typography>
            <TextField
              select
              placeholder="Select Event"
              variant="standard"
              sx={{ width: '300px' }}
              value={selectedEvent?.name || ""}
              onChange={(e) => handleSelectEvent(selectedDay.events.find(event => event.name === e.target.value))}
            >
              {selectedDay.events.map((event, index) => (
                <MenuItem key={index} value={event.name}>
                  {event.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        )}

        {selectedDay && selectedEvent && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}>
          <Button
            variant="text"
            sx={{
              color: qrOn ? "black" : dark_red
            }}
            onClick={() => setQrOn(prev => !prev)}
          >
            {qrOn ? "Stop Scanning" : "Start Scanning"}
          </Button>
        </Box>
        )}
      </Box>

      {selectedDay && selectedEvent && (
        <Box sx={{ position: 'relative', width: '100%' }}>
          <video
            ref={videoEl}
            style={{
              width: '100%',
              height: 'auto',
              border: '1px solid black',
              display: qrOn ? 'block' : 'none',
            }}
          ></video>
        </Box>
      )}
    </Box>
  );
};

export default AdminQrScanner;
