import InfoIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, FormControl, MenuItem, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { ICountry, countries } from "countries-list";
import React, { useEffect, } from "react";
import { useForm } from "react-hook-form";
import { light_red } from "../../../utils/colors";
import { updateUserPrefs } from "../../../utils/mutations/users";
import { User } from "../../../utils/types";

// Define FormData interface
interface FormData {
  preferredName: string;
  birthdate: string;
  nationality: string;
  dietaryRestriction: string;
  foodAllergies?: string;
  accomodations?: string;
}

interface PreferencesTabProps {
  user: User;
}

const PreferencesTab = ({ user }: PreferencesTabProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>(); 
  const isSmallScreen = useMediaQuery('(max-width:800px)');

  useEffect(() => {
    if (user.preferences) {
      const data = JSON.parse(user.preferences);
      setValue("preferredName", data.preferredName);
      setValue("birthdate", data.birthdate);
      // setNationality(data.nationality); // Update the nationality state variable
      setValue("dietaryRestriction", data.dietaryRestriction);
      setValue("foodAllergies", data.foodAllergies);
      setValue("accomodations", data.accomodations);
    }
  }, [user, setValue]);

  const onSubmitPreferences = async (data: FormData) => {
    // Handle form submission
    await updateUserPrefs(user.uid, JSON.stringify(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPreferences)}>
      <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={4}>

        {/* Personal Section */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: isSmallScreen ? '100%' : '300px'
        }}>
          <Typography sx={{ color: light_red, fontWeight: "bold", fontSize: "1.75rem" }}>
            Personal
          </Typography>
          <FormControl fullWidth>
            <Typography component="span" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
              Preferred Name
              <Tooltip title="This name will appear on your delegate badge and certificate of participation exactly as it is typed, including capitalization and formatting. It's important to ensure that the information is typed correctly, as it may be difficult to change later." sx={{ marginLeft: '5px' }}>
                <InfoIcon sx={{ fontSize: 'medium', marginLeft: '5px' }} />
              </Tooltip>
            </Typography>
            <TextField
              {...register("preferredName", { required: true })}
              error={!!errors.preferredName}
              helperText={errors.preferredName && "Preferred Name is required"}
              fullWidth
              variant="standard"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Birthdate</Typography>
            <TextField
              type="date"
              {...register("birthdate", { required: true })}
              error={!!errors.birthdate}
              helperText={errors.birthdate && "Birthdate is required"}
              fullWidth
              variant="standard"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Primary Nationality</Typography>
            <TextField
              select
              {...register("nationality", { required: true })}
              error={!!errors.nationality}
              helperText={errors.nationality && "Nationality is required"}
              fullWidth
              variant="standard"
              defaultValue={user.preferences ? JSON.parse(user.preferences).nationality : ""}
            >
              <MenuItem value="">Select a country</MenuItem>
              {Object.values(countries).sort((a:ICountry, b:ICountry)=>{
                return a.name.localeCompare(b.name);
              }).map((country) => (
                <MenuItem key={country.name} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>

        {/* Dietary Information Section */}
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: isSmallScreen ? '100%' : '300px'
        }}>
            <Typography sx={{ color: light_red, fontWeight: "bold", fontSize: "1.75rem" }}>
                Dietary
            </Typography>
            <FormControl fullWidth>
                <Typography sx={{ marginBottom: 1 }}>Dietary Restriction</Typography>
                <TextField
                    select
                    {...register("dietaryRestriction", { required: true })}
                    defaultValue={user.preferences ? JSON.parse(user.preferences).dietaryRestriction : "None"}
                    error={errors.dietaryRestriction ? true : false}
                    fullWidth
                    variant="standard"
                >
                    <MenuItem value="none">
                        None
                    </MenuItem>
                    <MenuItem value="vegetarian">
                        <Tooltip title="Vegetarian: Does not eat meat or fish, but may consume dairy and eggs.">
                            <Typography>
                                Vegetarian
                            </Typography>
                        </Tooltip>
                    </MenuItem>
                    <MenuItem value="vegan">
                        <Tooltip title="Vegan: Does not consume any animal products, including meat, dairy, eggs, and honey.">
                            <Typography>
                                Vegan
                            </Typography>
                        </Tooltip>
                    </MenuItem>
                    <MenuItem value="halal">
                        <Tooltip title="Halal: Food permissible for Muslims, prepared according to Islamic dietary laws.">
                            <Typography>
                                Halal
                            </Typography>
                        </Tooltip>
                    </MenuItem>
                    <MenuItem value="gluten-free">
                        <Tooltip title="Gluten-free: Does not contain gluten, a protein found in wheat, barley, and rye.">
                            <Typography>
                                Gluten-free
                            </Typography>
                        </Tooltip>
                    </MenuItem>
                </TextField>
                {errors.dietaryRestriction && (
                    <Typography variant="body2" color="error">
                        Dietary Restriction is required
                    </Typography>
                )}
            </FormControl>
            <FormControl fullWidth>
                <Typography sx={{ marginBottom: 1 }}>Do you have any food allergies?</Typography>
                <TextField variant="standard" fullWidth {...register("foodAllergies")} />
            </FormControl>
        </Box>
        {/* Additional Section */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: isSmallScreen ? '100%' : '300px'
        }}>
          <Typography sx={{ color: light_red, fontWeight: "bold", fontSize: "1.75rem" }}>
            Additional
          </Typography>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Physical Accommodations</Typography>
            <TextField variant="standard" fullWidth multiline {...register("accomodations")} />
          </FormControl>
        </Box>

      </Box>
      {/* Submit Button */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: "20px", width: "200px" }}
        >
          Save Preferences
        </Button>
      </Box>
    </form>
  );
};

export default PreferencesTab;