import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface CarouselProps {
  interval?: number; // Interval for auto-rotation in milliseconds
  images: string[]; // Array of image URLs
  sx?: React.CSSProperties;
}

const Carousel: React.FC<CarouselProps> = ({ interval = 3000, sx, images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(intervalId);
  }, [interval, images]);

  const goToNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', ...sx }}>
      {images.map((imageUrl, index) => (
        <Box
          key={index}
          style={{
            position: 'absolute',
            top: 0,
            left: `calc(100% * ${index - activeIndex})`, // Slide in from left for the previous image, from right for the next image
            width: '100%',
            height: '100%',
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'contain', // Fit the image within the Box
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: 'left 0.5s',
          }}
        />
      ))}
      <IconButton
        aria-label="Previous"
        onClick={goToPrev}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
        }}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        aria-label="Next"
        onClick={goToNext}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
        }}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default Carousel;
