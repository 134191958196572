import { Box } from '@mui/material';
import React from 'react';
import { Product } from '../../../../utils/types';
import TableWidget from '../../../widgets/TableWidget';
import ProductModal from './ProductModal'; // Import the new modal component
import PurchaserModal from './PurchaserModal';



interface ProductTableProps {
  products: Product[];
  confCode: string;
}

const ProductTable: React.FC<ProductTableProps> = ({ products, confCode }) => {
    const headers = ['Image', 'Name', 'Price', 'Purchasers', ''];

    const rows = products.map((product) => [
        <img src={product.imageUrl} alt={product.name} style={{ width: '50px' }} />, // Render the image as JSX
        product.name,
        `$${product.price}`,
        <PurchaserModal confCode={confCode} product={product} />,
        <ProductModal product={product} confCode={confCode} />
    ]);

    return (
        <Box sx={{
            padding: '20px',
        }}>
            <TableWidget
                headers={headers}
                rows={rows}
                search={false} // Set to false if you don't want to enable search
                tabs={false} // Set to false if you don't want to enable pagination
                sx={{
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    marginTop: '16px',
                }}
            />
            <br />
            <ProductModal confCode={confCode} />
        </Box>
    );
};

export default ProductTable;
