import { CssBaseline, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import './App.css';
import ConfPage from './components/confPage/ConfPage';
import FAQs from './components/confPage/faqs/FAQs';
import MapPage from './components/confPage/mapPage/MapPage';
import Schedule from './components/confPage/schedule/Schedule';
import SettingsPage from './components/confPage/settings/Settings';
import Store from './components/confPage/store/Store';
import Home from './components/home/Home';
import { auth } from './utils/firebase';
import { getUserData, syncUsers } from "./utils/mutations/users";

import AdminConference from './components/adminPage/adminConference/AdminConference';
import AdminGroups from './components/adminPage/adminGroups/adminGroups';
import AdminHome from './components/adminPage/adminHome/AdminHome';
import AdminUsers from './components/adminPage/adminUsers/AdminUsers';

import type { User } from './utils/types';


// Create a theme instance for the entire app
const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#770312"
    },
    secondary: {
      main: "#ffffff", // A white secondary color
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h5: {
      fontSize: '1.5rem', // Customize the font size for h5 typography
    },
  },
  shape: {
    borderRadius: 8, // Adjust the global border radius
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Override the all caps text transformation for all buttons
        },
      },
    },
  },
});



export default function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailIsVerified, setEmailIsVerified] = useState<boolean>(false);

  const navigate = useNavigate();
 
  /**
   * Handles user authentication and data synchronization with the database.
   * 
   * This useEffect hook sets up an observer on the Firebase Authentication object,
   * which listens for changes to the user's authentication state. When the user's
   * authentication state changes, the following happens:
   * 
   * 1. If the user is signed in:
   *    - The user's data is synchronized with the database, if it hasn't been
   *      already (i.e., if the user's displayName is not null).
   *    - The user's email verification status is updated in the component state.
   *    - If the user is an admin, they are redirected to the admin page.
   *    - If the user is an observer, they are redirected to the admin page.
   *    - If the user has a conferenceCode, they are redirected to the conference page.
   *    - The user's data is retrieved from the database and stored in the component state.
   * 
   * 2. If the user is signed out:
   *    - The conference ID and current user data are cleared from the component state.
   *    - The user is redirected to the home page.
   * 
   * The cleanup function returned by the useEffect hook unregisters the Firebase
   * observer when the component unmounts, preventing memory leaks.
   * 
   * @param {Function} navigate - The React Router `navigate` function.
   * @returns {void}
   */
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      setIsLoading(true);
      if (user) {
        // if the user is signed in, sync the user data with the database
        if(user.displayName){
          // if the displayName hasn't been added yet, don't sync the user
          syncUsers({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            conferenceCode: null,
            paymentTime: null,
            transactionId: null,
            ticketClass: null,
          } as User);
        }
        
        setEmailIsVerified(user.emailVerified);

        const idTokenResult = await user.getIdTokenResult();

        if(idTokenResult.claims.accessLevel && (idTokenResult.claims.accessLevel === 1 || idTokenResult.claims.accessLevel === 2)){          
          let userData = await getUserData(user.uid);
          if (userData !== null) {
            if (idTokenResult.claims.accessLevel === 1) {
              userData = { ...userData, accessLevel: 1 };
              setCurrentUser(userData);
              if(!window.location.pathname.startsWith(`/ADMIN`)){
                navigate('/ADMIN');
              }
            } else if (idTokenResult.claims.accessLevel === 2) {
              userData = { ...userData, accessLevel: 2 };
              setCurrentUser(userData);
              if(!window.location.pathname.startsWith(`/ADMIN`)){
                navigate('/ADMIN');
              }
            }
          }
        }  
        else {
          // if the user is signed in, set the currentUser if the user has verified their email
          const userData = await getUserData(user.uid);
          if(userData !== null){
            setCurrentUser(userData);
            // if the user has a conferenceCode, set the conferenceID and navigate to the conference page
            if (userData?.conferenceCode) {
              const conferenceCode = userData.conferenceCode.slice(0, 7);
              if (!window.location.pathname.startsWith(`/${conferenceCode}/`)) {
                navigate(`/${conferenceCode}`);
              }
            } 
          }
        }
      } else {
        // if the user is signed out, clear the conferenceID and currentUser and navigate to the home page
        setCurrentUser(null);
        navigate(`/`);
      }
      setIsLoading(false);
    });
    // Un-register Firebase observers when the component unmounts.
    return () => unregisterAuthObserver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const isSmallScreen = useMediaQuery('(max-width:800px)');

  return (
  <ThemeProvider theme={mdTheme}>
    <CssBaseline />
      <>
        {/* Don't show the main window if the window is loading (i.e. auth state is changing). Prevents flickering. */}
        {isLoading ? <Typography>Loading...</Typography> :
          <Routes>
            <Route path="/" element={<Home user={currentUser} setUser={setCurrentUser} emailVerified={emailIsVerified} setEmailVerified={setEmailIsVerified}/>} />
            {/* <Route path="/VSUMMIT/*" element={<SuccessPage user={currentUser} />} /> */}

            {currentUser && <Route path="/ADMIN/" element={<AdminHome user={currentUser} />} />}
            {currentUser && <Route path="/ADMIN/users" element={<AdminUsers user={currentUser} />} />}
            {currentUser && <Route path="/ADMIN/:confCode/" element={<AdminConference isSmallScreen={isSmallScreen} user={currentUser} />} />}
            {currentUser && <Route path="/ADMIN/groups" element={<AdminGroups />} />}

            {currentUser && <Route path="/:confCode/" element={<ConfPage user={currentUser} isSmallScreen={isSmallScreen} />} />}
            {currentUser && <Route path="/:confCode/settings" element={<SettingsPage user={currentUser} isSmallScreen={isSmallScreen} />} />}
            {currentUser && <Route path="/:confCode/store" element={<Store user={currentUser} isSmallScreen={isSmallScreen} />} />}
            {currentUser && <Route path="/:confCode/faqs" element={<FAQs user={currentUser}/>} />}
            {currentUser && <Route path="/:confCode/schedule" element={<Schedule user={currentUser} isSmallScreen={isSmallScreen} />} />}
            {currentUser && <Route path="/:confCode/map" element={<MapPage user={currentUser} isSmallScreen={isSmallScreen} />} />}
            {/* add default route that shows no routes found */}
            <Route path="*" element={<Typography>404: Not Found</Typography>} />
          </Routes>
        }
      </>
  </ThemeProvider>
  );
}
