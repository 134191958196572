import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { dark_red } from '../../utils/colors';

interface CustomInputProps extends Omit<TextFieldProps, 'type' | 'onChange'> {
  label: string;
  id: string;
  register: UseFormRegisterReturn;
  type: 'text' | 'password';
  width?: number;
  sx?: any;
  errorText?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  id,
  register,
  type,
  width = 100,
  sx,
  errorText,
  ...rest
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ marginBottom: '15px' }}>
      <Typography variant="body1" sx={{ color: 'black', marginBottom: '0.25rem', fontWeight: 'bold' }}>
        {label}
      </Typography>
      <TextField
        id={id}
        variant="standard"
        placeholder={label}
        helperText={errorText}
        error={!!errorText}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : 'text'}
        InputProps={{
          endAdornment: type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
          sx: {
            backgroundColor: 'white',
            borderRadius: '5px',
            width: `${300 * width / 100}px`,
            padding: '0.25rem',
            ':after': { borderBottomColor: dark_red },
            ...sx,
          }
        }}
        {...register}
        {...rest}
      />
    </FormControl>
  );
};

export default CustomInput;
