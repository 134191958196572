import React, {useEffect} from 'react';
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Conference } from "../../../utils/types";
import { subscribeToUsersInConf } from '../../../utils/mutations/users';
import { User } from '../../../utils/types';
import { dark_red } from '../../../utils/colors';

interface AdminConferenceCardProps {
  conf: Conference;
}

const AdminConferenceCard: React.FC<AdminConferenceCardProps> = ({ conf }) => {
  const navigate = useNavigate();

  const [registrants, setRegistrants] = React.useState<number>(0);

  useEffect(() => {
    const unsubscribeUsersInConf = subscribeToUsersInConf(conf?.conferenceCode, (users: User[]): void => {
      if (users) {
        setRegistrants(users.length);
      } else {
        console.log(`Users in conference with code ${conf?.conferenceCode} not found`);
      }
    });

    return () => {
      unsubscribeUsersInConf();
    };
  }, [conf?.conferenceCode]);

  return (
    <Paper sx={{ margin: '10px', height: '100%', width: '300px', elevation: 10, borderRadius: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '15px',
        }}
      >
        <Typography
          component="div"
          variant="h6"
          onClick={() => { navigate(`/ADMIN/${conf?.conferenceCode}`) }}
          sx={{
            textAlign: 'center',
            cursor: 'pointer',
            color: dark_red,
            fontWeight: 'bold',
          }}
        >
          {conf?.conferenceCode}
        </Typography>
        <Divider sx={{ marginY: '10px' }} />
        <Typography variant="subtitle1">{conf?.conferenceName}</Typography>
        <Typography variant="subtitle1">{registrants} Registrants</Typography>
        <Typography variant="subtitle1">Prices:</Typography>
        {Object.entries(conf?.prices || {}).map(([category, price]) => (
          <Typography key={category} variant="subtitle2" sx={{ marginLeft: '20px' }}>
            {`${category}: $${price}`}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
};

export default AdminConferenceCard;
