import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { updateConference } from '../../../../utils/mutations/conferences';
import { Conference, User } from '../../../../utils/types';
import TableWidget from '../../../widgets/TableWidget';

interface Props {
  conference: Conference;
  registrants: User[];
}

export default function EditConferenceModal({ conference, registrants }: Props) {
  const [editing, setEditing] = useState<boolean>(false);

  const [conferenceName, setConferenceName] = useState<string>(conference?.conferenceName || '');

  const [editedLabels, setEditedLabels] = useState<string[]>(Object.keys(conference?.prices || []));
  const [editedValues, setEditedValues] = useState<number[]>(Object.values(conference?.prices || []));

  const resetState = async () => {
    setConferenceName(conference?.conferenceName || '');
    setEditedLabels(Object.keys(conference?.prices || []));
    setEditedValues(Object.values(conference?.prices || []));
  };

  const handleUpdateConference = () => {
    if (conferenceName === '') {
      alert("Conference name cannot be empty!");
      return;
    }

    if (editedLabels.includes("")) {
      alert("Price labels cannot be empty!");
      return;
    }

    const password = prompt("Please enter your password to confirm changes:");
    if (password !== "admin123") {
      alert("Incorrect password!");
      return;
    }

    const editedPrices = editedLabels.reduce((obj, label, index) => {
      obj[label] = editedValues[index];
      return obj;
    }, {} as Record<string, number>);

    const updatedConference = {
      conferenceCode: conference.conferenceCode,
      conferenceName: conferenceName,
      prices: editedPrices,
    };

    updateConference(updatedConference);
    resetState();
    setEditing(false);
  };

  const handleAddPrice = () => {
    setEditedLabels((prevLabels) => [...prevLabels, '']);
    setEditedValues((prevValues) => [...prevValues, 0]);
  };

  const handleRemovePrice = (index: number) => {
    if (editedLabels.length === 1) { return; }

    const updatedLabels = [...editedLabels];
    const updatedValues = [...editedValues];

    updatedLabels.splice(index, 1);
    updatedValues.splice(index, 1);

    setEditedLabels(updatedLabels);
    setEditedValues(updatedValues);
  };

  const handlePriceChange = (index: number, type: string, value: string) => {
    if (type === 'label') {
      if (!value.match(/[A-Z]/) && value !== "") { return; }

      setEditedLabels((prevLabels: string[]) => {
        const updatedLabels = [...prevLabels];
        updatedLabels[index] = value;
        return updatedLabels;
      });

    } else if (type === 'value') {
      if (isNaN(Number(value))) { return; }

      setEditedValues((prevValues: number[]) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = Number(value);
        return updatedValues;
      });

    }
  };

  const toggleEditing = () => {
    setEditing((prev) => !prev);
  };

  const conferenceRows: [string, number, string][] = conference.prices 
  ? Object.keys(conference.prices).map((label) => [
      label, 
      registrants.filter((user) => user.ticketClass === label).length, 
      `$${conference.prices![label]}`  // The non-null assertion operator is used here
    ])
  : [];

  return (
    <Box sx={{ 
      padding: '20px', 
      width: '100%',
      textAlign: 'center', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center',
    }}>
      {editing ? (
        <TextField
          sx={{
            width: '50%'
          }}
          label="Conference Name"
          variant="standard"
          value={conferenceName}
          onChange={(e) => setConferenceName(e.target.value)}
          size="small"
        />
      ) : (
        <Typography variant="h5">Ticket Classes</Typography>
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '20px', // added padding for better spacing
      }}>
        { editing ? editedLabels.map((label, index) => (
            <Box key={index} sx={{ 
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center',
              marginTop: '8px',
              width: '50%',
              gap: '20px'
            }}>
              <TextField
                sx={{ width: '25%' }}
                label={`Price Label ${index + 1}`}
                variant="standard"
                value={label}
                onChange={(e) => handlePriceChange(index, 'label', e.target.value)}
                inputProps={{ maxLength: 1 }}
                size="small"
              />
              <TextField
                sx={{ width: '50%' }}
                label={`Price Value ${index + 1}`}
                variant="standard"
                value={editedValues[index]}
                size="small"
                onChange={(e) => handlePriceChange(index, 'value', e.target.value)}
              />
              <IconButton onClick={() => handleRemovePrice(index)}>
                <CloseIcon />
              </IconButton>
            </Box>
          )
          ) : (
            <TableWidget
              headers={['Ticket Class', 'Registrants', 'Price']}
              rows={conferenceRows}
              search={false} 
              tabs={false}
            />
        )}
      </Box>
  
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2, width: '100%' }}>
        {editing && <Button variant="text" onClick={handleAddPrice}>
          Add Price
        </Button>}
        {editing ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}>
            <Button variant="text" onClick={toggleEditing} sx={{ margin: '0px 20px' }}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={editing ? handleUpdateConference : toggleEditing}>
              Save
            </Button>
          </Box>
        ) : (
          <Button variant="outlined" onClick={toggleEditing}>
            Edit
          </Button>
        )}
      </Box>
    </Box>
  );
}
