// import axios from 'axios';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from 'axios';
import { sha256 } from 'crypto-hash';
import React, { useEffect, useState } from 'react';
import type { Payment, User } from '../../utils/types';
import { Box, Button, Backdrop } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {Snackbar, Alert} from '@mui/material';
import { addPaymentInfo } from '../../utils/mutations/payments';

// ! I need to wrap the PayPal button

interface PaymentWidgetProps {
    user: User;
    products: { [productId: string]: number } | null;
    joinCode: string | null;
    onSuccess: (payment: Payment) => void;
}

interface CustomWindow extends Window {
    appendHelcimPayIframe?: (checkoutToken: string, param: boolean) => void;
    removeHelcimPayIframe?: () => void;
}

const PaymentWidget: React.FC<PaymentWidgetProps> = ({ user, products, joinCode, onSuccess }: PaymentWidgetProps) => {
    const [secretToken, setSecretToken] = useState<string | null>(null);
    const [checkoutToken, setCheckoutToken] = useState<string | null>(null);
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);

    const customWindow = window as CustomWindow;

    const handlePaymentSuccess = async (payment: Payment) => {
        try {
            await addPaymentInfo(payment);

            await axios.post("https://completepayment-2t5cbdn56q-uc.a.run.app", {
                uid: user.uid,
                paymentId: payment.transactionId,
                cart: createCart(joinCode, products),
            });

            onSuccess(payment);

        } catch (error) {
            console.error(error);        
        }        
    }

    // ! TODO: This should probably be moved into the backend (i.e., a function)
    // validate that the payment was processed correctly by checking the hash
    const validatePayment = async (message: any, secretToken: string) => {
        const msg = JSON.parse(message);
        const stringToHash = JSON.stringify(msg.data.data) + secretToken;
        const hash = await sha256(stringToHash);

        if(hash !== msg.data.hash){
            throw new Error('Hashes do not match!');
        }

        return msg.data.data;
    }

    const createCart = (joinCode: string | null, products: { [productId: string]: number } | null) => {
        const cart = [];
    
        if (products) {
            Object.keys(products).forEach((productId) => {
                const quantity = products[productId];
                cart.push({
                    type: "product",
                    item: productId,
                    quantity: quantity,
                });
            });
        }
    
        if (joinCode) {
            cart.push({
                type: "ticket",
                item: joinCode,
                quantity: 1,
            });
        }
        return cart;
    };
    
    
    const setHelcimListener = () => {
        // set a listener for the payment modal
        window.addEventListener('message', (event) => {
            const helcimPayJsIdentifierKey = 'helcim-pay-js-' + checkoutToken;
  
            if(event.data.eventName === helcimPayJsIdentifierKey){
                
                if(event.data.eventStatus === 'ABORTED'){
                    console.error('Transaction failed!', event.data.eventMessage);
                }
                
                if(event.data.eventStatus === 'SUCCESS' && secretToken){
                    validatePayment(event.data.eventMessage, secretToken)
                    .then((data) => {
                        const payment : Payment = {
                            type: data.type,
                            amount: data.amount,
                            transactionId: data.transactionId,
                            item: joinCode || 'store purchase',
                            uid: user.uid,
                            dateCreated: data.dateCreated,
                            customerCode: data.customerCode,
                        }
                        if(customWindow.removeHelcimPayIframe){
                            customWindow.removeHelcimPayIframe();
                        } else {
                            console.error('removeHelcimPayIframe is not available on window');
                        }
                        handlePaymentSuccess(payment);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                }
            }
        });
    }

    // validate the join code and get the checkout token
    const initializeHelcimPayment = async () => {
        setSnackBarOpen(true);
        axios.post('https://initializehelcim-2t5cbdn56q-uc.a.run.app', {
            email: user.email,
            cart: createCart(joinCode, products),
        })
        .then((response) => {
            console.log(response)
            if (response.data.secretToken && response.data.checkoutToken) {
                console.log(response.data)
                // set the secret token and checkout token
                setSecretToken(response.data.secretToken);
                setCheckoutToken(response.data.checkoutToken);
            }
        })
        .catch((err) => {
            console.error(err);
        })
    }

    // open the payment modal when the checkout token is available
    useEffect(() => {
        // open the payment modal
        if (checkoutToken) {
            setHelcimListener();
            if (customWindow.appendHelcimPayIframe) {
                customWindow.appendHelcimPayIframe(checkoutToken, true);
            } else {
                console.error('appendHelcimPayIframe is not available on window');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutToken]);

    const initializePayPalPayment = async (_: any, __: any) => {
        try {
            const response = await axios.post("https://initializepaypal-2t5cbdn56q-uc.a.run.app", {
                email: user.email,
                cart: createCart(joinCode, products),
            });

            console.log(response.data);

            if (response.data.id) {
                return response.data.id;
            } else {
                const errorDetail = response.data.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${response.data.debug_id})`
                    : JSON.stringify(response);
                throw new Error(errorMessage);
            }
        } catch (err) {
            console.error(err);
            throw new Error('Failed to initialize PayPal payment');
        }
    };    

    // check Approval
    const onApprove = (_: any, actions: any) => {
        return actions.order.capture().then(async (details: any) => {
            const payment : Payment = {
                amount: details.purchase_units[0].amount.value,
                item: joinCode || 'store purchase',
                type: 'PayPal',
                customerCode: details.payer.payer_id,
                transactionId: details.purchase_units[0].payments.captures[0].id,
                dateCreated: new Date(),
                uid: user.uid,
            }            
            handlePaymentSuccess(payment);
        });
    };

    // capture likely error
    const onError: (err: any, actions?: any) => void = (data, actions) => {
        console.error(data, actions);
        console.error('An error occurred during the PayPal payment process.');
    };

    const PAYPAL_API_KEY = "ARsdr9xZ_YIcxR7_-xbr5zZVYEodzlUtyZH-BQP_IoV5PU5bMbsD3Y1zXVlv9D7QHjezx86E7hfc_25X"
    // const PAYPAL_API_KEY = "Ad1TLtgzVXT63b98Sf0sdwSTTF7o11uFw_jLplNEQNYdzVlyGC6-liUaPy2D85Aie2WKxr-BrzNCBmFn";

    return (
        <>
          <Backdrop
            open={snackBarOpen}
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: 'fixed', // Change position to 'fixed'
              top: 0, // Add top, left, right, and bottom properties
              left: 0,
              width: '100vw', // Set width and height to cover the entire viewport
              height: '100vh',
            }}
          >
            <Snackbar
              open={snackBarOpen}
              autoHideDuration={2000}
              onClose={() => setSnackBarOpen(false)}
              message="This Snackbar will be dismissed in 5 seconds."
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              sx={{
                zIndex: 9999,
              }}
            >
              <Alert severity="info">Payment window opening...</Alert>
            </Snackbar>
          </Backdrop>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={initializeHelcimPayment}
              style={{ marginTop: "8px", color: "black", backgroundColor: "white", borderRadius: "4px" }}
              startIcon={<CreditCardIcon />}
            >
              Pay with Credit Card
            </Button>
            <PayPalScriptProvider options={{ "client-id": PAYPAL_API_KEY }}>
              <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={initializePayPalPayment}
                onApprove={onApprove}
                onError={onError}
                fundingSource={"paypal"}
              />
            </PayPalScriptProvider>
          </Box>
        </>
      );
};

export default PaymentWidget;
