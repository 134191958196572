import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminAccountMenu from './AdminAccountMenu';
import { dark_red } from '../../../utils/colors';

const menuItems = [
                    { name: 'Groups', path: 'groups' },
                    { name: 'Users', path: 'users' },
                  ];

export default function MenuBar() {
  const navigate = useNavigate();
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMobileMenuToggle = (event : any) => {
    setMobileMenuAnchor(isSmallScreen ? event.currentTarget : null);
  };

  const handleMobileMenuItemClick = (path : string) => {
    navigate(`/ADMIN/${path}`);
    setMobileMenuAnchor(null);
  };

  return (
    <AppBar position="fixed" sx={{ height: '64px', zIndex: 1, backgroundColor: 'white', }} elevation={0}>
      <Toolbar sx={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'space-between' }}>
        {isSmallScreen && (
          <>
            <IconButton color="inherit" onClick={handleMobileMenuToggle}>
              <MenuIcon />
            </IconButton>
            <Button onClick={() => navigate(`/ADMIN`)}sx={{ width: '300px', '&:hover': { backgroundColor: 'transparent' } }} disableFocusRipple disableRipple disableTouchRipple>
                <div style={{ width: '100%', height: '64px', background: `url("/art/HPAIR Logo Banner (Black).png") no-repeat center/contain` }} />
            </Button>
            <AdminAccountMenu />
          </>
        )}
        {!isSmallScreen && (
          <>
            <Button onClick={() => navigate(`/ADMIN`)}sx={{ width: '300px', '&:hover': { backgroundColor: 'transparent' } }} disableFocusRipple disableRipple disableTouchRipple>
                <div style={{ width: '100%', height: '64px', background: `url("/art/HPAIR Logo Banner (Black).png") no-repeat center/contain` }} />
            </Button>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {menuItems.map((menuItem) => (
                <Button
                  key={menuItem.path}
                  sx={{
                    color: (window.location.pathname.includes(menuItem.path) ? dark_red : 'inherit'),
                    '&:hover': { 
                      backgroundColor: 'transparent' 
                    }
                  }}
                  onClick={() => navigate(`/ADMIN/${menuItem.path}`)}
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                >
                  {menuItem.name}
                </Button>
              ))}
              <AdminAccountMenu />
            </Box>
          </>
        )}
      </Toolbar>

      {isSmallScreen && (
        <Menu
          open={Boolean(mobileMenuAnchor)}
          onClose={() => setMobileMenuAnchor(null)}
          anchorEl={mobileMenuAnchor}
        >
          {menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.path}
              onClick={() => handleMobileMenuItemClick(menuItem.path)}
            >
              {menuItem.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </AppBar>
  );
}
