import { Box, Button, Typography } from "@mui/material";
import React from 'react';
import { useForm } from "react-hook-form";
import { dark_red } from '../../utils/colors';
import { auth } from '../../utils/firebase';
import { syncUsers } from '../../utils/mutations/users';
import type { User } from '../../utils/types';
import CustomInput from "../widgets/CustomInput";



// ! I need to figure out how I want the email verification to look.

interface SignUpProps {
  setIsSignUp: (isSignUp: boolean) => void;
  setUser: (user: User) => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export default function SignUp({ setIsSignUp, setUser }: SignUpProps) {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<FormData>();

  const handleSignUp = async (formData: FormData) => {
    if (formData.password !== formData.confirmPassword) {
        setError("confirmPassword", { type: "manual", message: "Passwords do not match" });
        return;
    }

    const name : string = `${formData.firstName} ${formData.lastName}`;

    try {
        // Create user with email and password
        const userCredential = await auth.createUserWithEmailAndPassword(formData.email, formData.password);

        // Update user profile with display name
        await userCredential.user?.updateProfile({
            displayName: name,
        });

        if (userCredential.user) {
          userCredential.user.sendEmailVerification()
          .then(() => {
              // Create user document in Firestore
              const user: User = {
                displayName: name,
                email: formData.email,
                uid: userCredential.user?.uid || "",
                conferenceCode: null,
                transactionId: null,
                ticketClass: null,
                paymentTime: null,
                preferences: null,
                events: null,
              };

              syncUsers(user).then(() => {
                setUser(user);
              });
          })
          .catch((error) => {
            console.error(error);
          });
        }

        // User is signed up with the name added to their profile and Firestore document.
        // You can handle redirection or any other logic here.
    } catch (error: any) {
        if (error.code === "auth/email-already-in-use") {
            // The provided email is already in use.
            setError("email", { type: "manual", message: "Email is already in use. Please choose a different email or sign in." });
        } else if (error.code === "auth/invalid-email") {
            // The provided email is not valid.
            setError("email", { type: "manual", message: "Please enter a valid email." });
        } else {
            // Handle other sign-up errors (e.g., display a general error message).
            console.error(error);
        }
    }
  };

  return (
    <Box style={{
      width: '300px'
    }}>
      <Typography variant="h4" sx={{
        color: 'black',
        fontWeight: 'bold',
        marginBottom: '10px',
      }}>Sign Up</Typography>

      <form onSubmit={handleSubmit(handleSignUp)}>
        <Box sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'row',
        
        }}>
        <CustomInput
          id="first-name"
          label="First Name"
          type="text"
          variant="standard"
          register={register("firstName", { required: true })}
          error={!!errors.firstName}
          helperText={errors.firstName && "First name is required"}
          color='secondary'
          width={50}
        />
        <CustomInput
          id="last-name"
          label="Last Name"
          type="text"
          variant="standard"
          register={register("lastName", { required: true })}
          error={!!errors.lastName}
          helperText={errors.lastName && "Last name is required"}
          color='secondary'
          width={50}
        />
        </Box>
        <CustomInput
          id="email"
          label="Email"
          type="text"
          variant="standard"
          register={register("email", { required: true })}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
          color='secondary'
        />
        <CustomInput
          type="password"
          label="Password"
          id="password"
          variant="standard"
          register={register("password", { required: true, minLength: 8 })}
          error={!!errors.password}
          helperText={errors.password && "Password must be at least 8 characters"}
          color='secondary'
        />
        <CustomInput
          type="password"
          label="Confirm Password"
          id="confirmPassword"
          variant="standard"
          register={register("confirmPassword", { required: true })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword && errors.confirmPassword.message}
          color='secondary'
        />
        <Button variant="contained" type="submit" sx={{
          width: '300px',
          backgroundColor: dark_red,
          color: 'white',
          borderRadius: '3px',
        }}>
          Sign Up
        </Button>
      </form>
      <Typography sx={{ margin: '8px 0', display: 'flex', alignItems: 'center', fontSize: 'inherit' }}>
        Already signed up?
        <Button
          onClick={() => setIsSignUp(false)}
          sx={{
            textDecoration: 'none',
            color: 'dark_red',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: 'inherit',
            p: '0px',
            marginLeft: '5px',
          }}
        >
          Sign in
        </Button>
      </Typography>
    </Box>
  );
}
