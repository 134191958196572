import { Menu, MenuItem, Tab, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { Day } from '../../../../utils/types';

interface DayTabProps extends React.ComponentProps<typeof Tab> {
    day: Day;
    handleChangeDate: (day: Day, newDate: string) => void;
    handleDelete: (day: Day) => void;
}

const DayTab = ({ day, handleChangeDate, handleDelete, ...props }: DayTabProps) => {
    const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number; mouseY: number } | null>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [newDate, setNewDate] = useState<string>(day.date || "");
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const preventBlur = useRef<boolean>(false);

    useEffect(() => {
        setNewDate(day.date ?? ""); // synchronize newDate with the day prop
    }, [day.date]);

    useEffect(() => {
        setIsDeleted(day.isDeleted ?? false);
    }, [day.isDeleted]);

    const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setContextMenuPosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleCloseContextMenu = () => {
        setContextMenuPosition(null);
    };

    const handleRenameClick = () => {
        preventBlur.current = true;
        handleCloseContextMenu();
        setEditMode(true);
        setTimeout(() => {
            preventBlur.current = false;
        }, 100); // slightly longer delay to ensure the context menu is closed
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sanitizedText = event.target.value.replace(/[^0-9/]/g, '');
        setNewDate(sanitizedText);
    };

    const handleBlur = () => {
        if (preventBlur.current) return;
        if (newDate !== "" && newDate !== day.date) {
            handleChangeDate(day, newDate);
        }
        setEditMode(false);
    };

    return (
        <>
            {!editMode ? (
                <Tab
                    label={<Typography style={{
                        textDecoration: isDeleted ? "line-through" : "none",                    
                    }}>{day.date}{day.isEditing ? " *" : ""}</Typography>}
                    onContextMenu={handleContextMenu}
                    {...props}
                />)
            : (
                <TextField
                    value={newDate}
                    onBlur={handleBlur}
                    onChange={handleDateChange}
                    autoFocus
                    sx={{
                        width: "100px",
                        alignSelf: "center",
                    }}
                    variant="standard"
                />
            )}

            <Menu
                open={contextMenuPosition !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={contextMenuPosition ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX } : undefined}
            >
                <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
                <MenuItem onClick={() => handleDelete(day)}>{day.isDeleted ? "Undo Delete" : "Delete"}</MenuItem>
            </Menu>
        </>
    );
};

export default DayTab;
